import user from "./reducer"

export * from "./actions"
export * from "./selectors"

export const {
  logout,
  updateUser,
  resetUnreadMessagesNumber,
  decrementUnfilledFormsNumber,
} = user.actions

export default user.reducer

import {
  Dialog,
  Card,
  DialogActions,
  Button,
  useMediaQuery,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useTheme } from "@emotion/react"
import "@whereby.com/browser-sdk"

const useStyles = makeStyles((theme) => ({
  videoMeetingModal: {
    "& .MuiDialog-paperWidthLg.MuiDialog-paperFullWidth": {
      height: "100%",
    },
    "& .MuiCard-root": {
      height: "95%",
    },
    "& ~ .MuiModal-root .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.65)",
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  meetingRoomDialogFooter: {
    justifyContent: "flex-end",
    "& a": {
      display: "flex",
      fontWeight: 500,
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
  },
}))

const VideoTestModal = ({ open, close: closeVideoTestModal }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isWidthSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  const videoTestUrl =
    "https://dinpsykolog.whereby.com/8reyrz?lang=sv&langOverride"

  return (
    <Dialog
      fullWidth={!isWidthSmDown}
      maxWidth="lg"
      fullScreen={isWidthSmDown}
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          closeVideoTestModal(false)
        }
      }}
      className={classes.videoMeetingModal}
    >
      <Card>
        <whereby-embed
          title="VideoTest"
          room={`${videoTestUrl}`}
          allow="camera;microphone"
          width="100%"
          height="100%"
          style={{ height: "100%" }}
        />
      </Card>
      <DialogActions className={classes.meetingRoomDialogFooter}>
        <Button
          size="small"
          color="default"
          onClick={() => closeVideoTestModal(false)}
          className={classes.closeButton}
        >
          Stäng
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default VideoTestModal

import { configureStore } from "@reduxjs/toolkit"
import config from "config"
import onboardingReducer from "./onboarding"
import userReducer from "./user"
import caregiverReducer from "./caregiver"
import chatReducer from "./chat"
import commonReducer from "./common"

const store = configureStore({
  reducer: {
    onboarding: onboardingReducer,
    user: userReducer,
    caregiver: caregiverReducer,
    chat: chatReducer,
    common: commonReducer,
  },
  devTools: !!config?.isDev,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store

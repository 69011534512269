import { createAsyncThunk } from "@reduxjs/toolkit"
import { getCaregiver } from "api/getCaregiver"
import { getCaregiverAbsence } from "api/getCaregiverAbsence"
import { getCaregiverAvailabilities } from "api/getCaregiverAvailabilities"
import { getCaregiverPublicData } from "api/getCaregiverPublicData"
import { isSameDay } from "date-fns"
import format from "date-fns/format"
import { convertDatesConsideringDaylightSavingTime } from "utils/convertDatesConsideringDaylightSavingTime"

export const fetchCaregiver = createAsyncThunk(
  "caregiver/fetchCaregiver",
  async (userId) => {
    const { data } = await getCaregiver(userId)

    return data
  }
)

export const fetchCaregiverPublicData = createAsyncThunk(
  "caregiver/fetchCaregiver",
  async (userId) => {
    const { data } = await getCaregiverPublicData(userId)

    return data
  }
)

export const fetchCaregiverAbsence = createAsyncThunk(
  "caregiver/fetchCaregiverAbsence",
  async ({ caregiverId, ...params }, thunkApi) => {
    try {
      let absence
      const { data } = await getCaregiverAbsence(caregiverId, { ...params })
      const absences = data?.sort((a, b) => a.startAt.localeCompare(b.startAt))
      if (absences?.length > 0) {
        ;[absence] = absences
      }
      return absence
    } catch (e) {
      thunkApi.rejectWithValue(e)
    }
  }
)

export const fetchCaregiverAvailabilities = createAsyncThunk(
  "caregiver/fetchCaregiverAvailabilities",
  async (
    { caregiverId, previousVideoMeetingStart, previousMeetingMissed = false },
    thunkApi
  ) => {
    try {
      const { data } = await getCaregiverAvailabilities(caregiverId)
      const dataAvailable = data?.available
        ? [...convertDatesConsideringDaylightSavingTime(data.available)]
        : []
      const availabilitiesGroupedByDate = dataAvailable.reduce(
        (available, slot) => {
          if (
            previousVideoMeetingStart &&
            isSameDay(
              new Date(previousVideoMeetingStart),
              new Date(slot.start)
            ) &&
            !previousMeetingMissed
          ) {
            return available
          }
          const dayDate = format(slot.start, "MMM d yyyy")
          if (!available[dayDate]) {
            available[dayDate] = []
          }
          available[dayDate].push({
            start: slot.start,
            end: slot.end,
            timeZone: slot.timeZone,
          })
          return available
        },
        {}
      )
      const availabilities = Object.keys(availabilitiesGroupedByDate).map(
        (dayDate) => ({
          dayDate,
          slots: availabilitiesGroupedByDate[dayDate],
        })
      )

      return availabilities
    } catch (e) {
      thunkApi.rejectWithValue(e)
    }
  }
)

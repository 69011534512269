import { createSelector } from "@reduxjs/toolkit"
import { selectUserState } from "redux/user"

const selectOnboardingState = (state) => state.onboarding

const getOnboarding = createSelector(
  selectOnboardingState,
  selectUserState,
  (onboarding, user) => {
    const { id: userId, onboardingFinished } = user

    return { ...onboarding, userId, onboardingFinished }
  }
)

export { selectOnboardingState, getOnboarding }

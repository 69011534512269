import { isToday, isTomorrow, format } from "date-fns"
import { sv } from "date-fns/locale"

export const getFormattedDateAndTime = (dateString) => {
  const date = new Date(dateString)
  if (isToday(date)) {
    return `Idag kl ${format(date, "HH.mm", {
      locale: sv,
    })}`
  }
  if (isTomorrow(date)) {
    return `Imorgon kl ${format(date, "HH.mm", {
      locale: sv,
    })}`
  }

  return `${format(date, "d MMM", {
    locale: sv,
  }).replace(/\./g, "")} kl ${format(date, "HH.mm", {
    locale: sv,
  })}`
}

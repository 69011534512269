import { isToday, isTomorrow, format } from "date-fns"
import { sv } from "date-fns/locale"

export const getFormattedDateWithDay = (dateString) => {
  const date = new Date(dateString)
  if (isToday(date)) {
    return "Idag"
  }
  if (isTomorrow(date)) {
    return "Imorgon"
  }

  return `${format(date, "EEEE d MMMM", {
    locale: sv,
  })}`
}

import common from "./reducer"

export const {
  showAlert,
  hideAlert,
  setInitialLoading,
  setToken,
  setChatStarted,
  setSocket,
  setSessionCount,
  setTreatmentStageImage,
} = common.actions

export default common.reducer

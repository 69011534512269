import { useState } from "react"
import { Box, Link, Typography } from "@mui/material"
import {
  Videocam as VideocamIcon,
  EventBusy as EventBusyIcon,
} from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import { isSameMonth, format, differenceInMinutes } from "date-fns"
import { sv } from "date-fns/locale"
import VideoPaymentModal from "components/VideoPaymentModal"
import FrikortModal from "components/FrikortModal"
import { sessionType } from "utils/sessionType"
import { showAlert } from "redux/common"
import { useDispatch } from "react-redux"

const useStyles = makeStyles((theme) => ({
  root: ({ type, bannerType }) => ({
    backgroundColor:
      type === bannerType.ABSENCE
        ? [theme.palette.warning.main]
        : [theme.palette.warning.dark],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
    color: "white",
    [theme.breakpoints.down("sm")]: {
      alignItems: "start",
    },
  }),
  bannerContent: {
    display: "inherit",
    alignItems: "flex-start",
    "& svg": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
    },
  },
  bannerText: {
    fontSize: "1rem",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85rem",
      textAlign: "left",
    },
  },
}))

const dateFormat = "d MMM"
const minutesDefaultPaymentExpiration = 25
const minutesBetweenMeetingCreationAndStartThreshold = 20
const minutesPaymentExpirationBeforeThreshold = 5
const minutesPaymentExpirationAfterThreshold = 10

const Banner = ({
  caregiverFirstName,
  absence,
  daysUntilAbsence,
  absenceDuration,
  videoMeetingPaymentLink,
  videoMeetingHasCustomPaymentExpiration,
  type,
  bannerType,
  videoMeetingId,
  videoMeetingStart,
  videoMeetingCreatedTime,
}) => {
  const classes = useStyles({ type, bannerType })
  const [openVideoPaymentModal, setOpenVideoPaymentModal] = useState(false)
  const [openFrikortModal, setOpenFrikortModal] = useState(false)
  const dispatch = useDispatch()
  const minutesBetweenMeetingCreationAndStart = differenceInMinutes(
    new Date(videoMeetingStart),
    new Date(videoMeetingCreatedTime)
  )
  // eslint-disable-next-line no-nested-ternary
  const minutesUntilPaymentExpiration = videoMeetingHasCustomPaymentExpiration
    ? minutesBetweenMeetingCreationAndStart <
      minutesBetweenMeetingCreationAndStartThreshold
      ? minutesPaymentExpirationBeforeThreshold
      : minutesPaymentExpirationAfterThreshold
    : minutesDefaultPaymentExpiration

  const getAbsenceHeader = () => {
    let header = `${caregiverFirstName} ${
      daysUntilAbsence <= 0 ? " är ledig " : " kommer vara ledig "
    }`
    if (absenceDuration === 0) {
      header += format(new Date(absence.startAt), dateFormat, { locale: sv })
    } else {
      header += format(
        new Date(absence.startAt),
        isSameMonth(new Date(absence.startAt), new Date(absence.endAt))
          ? "d"
          : dateFormat,
        { locale: sv }
      )
      header += " - "
      header += format(new Date(absence.endAt), dateFormat, { locale: sv })
    }

    header = header.replace(/\./g, "")

    return header
  }

  return (
    <Box className={classes.root}>
      {type === bannerType.ABSENCE ? (
        <Box className={classes.bannerContent}>
          <EventBusyIcon />
          <Typography variant="h6" className={classes.bannerText}>
            {getAbsenceHeader()}
          </Typography>
        </Box>
      ) : (
        <>
          <Link
            underline="always"
            className={classes.bannerContent}
            color="white"
            component="button"
            variant="h6"
            onClick={() => setOpenVideoPaymentModal(true)}
          >
            <VideocamIcon />
            <Typography variant="subtitle2" className={classes.bannerText}>
              Din tid är reserverad i {minutesUntilPaymentExpiration} min.
              Vänligen bekräfta bokningen nedan för att behålla tiden.
            </Typography>
          </Link>
          <VideoPaymentModal
            open={openVideoPaymentModal}
            close={() => {
              setOpenVideoPaymentModal(false)
            }}
            onPaymentDecision={() => {
              setOpenVideoPaymentModal(false)
              setOpenFrikortModal(true)
            }}
            showAlert={({ alertType, message }) =>
              dispatch(showAlert({ alertType, message }))
            }
            videoMeetingPaymentLink={videoMeetingPaymentLink}
          />
          <FrikortModal
            open={openFrikortModal}
            close={() => setOpenFrikortModal(false)}
            goBack={() =>
              openFrikortModal
                ? (setOpenFrikortModal(false), setOpenVideoPaymentModal(true))
                : null
            }
            sessionType={sessionType.VIDEO}
            videoMeetingId={videoMeetingId}
          />
        </>
      )}
    </Box>
  )
}

export default Banner

import { isAfter, isValid } from "date-fns"

/**
 * Validates if a session timestamp has not expired
 *
 * @param {Object} [session]
 * @param {string|number} session.endedAt
 * @returns {boolean}
 */

function isActiveSession(session) {
  const { endedAt } = session || {}
  const date = new Date(endedAt)

  if (!isValid(date)) {
    return false
  }

  return isAfter(date, new Date())
}

export default isActiveSession

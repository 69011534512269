import Cookie from "js-cookie"
import config from "config"
import { createSlice } from "@reduxjs/toolkit"
import {
  getUserProfile,
  updateUserProfile,
  updateUserAvatar,
  setUserCustomerStatusInactive,
  fetchUserMeetings,
  fetchUserNotifications,
} from "./actions"
import { finishOnboarding } from "../onboarding"

const initialState = {
  loading: false,
  error: false,
}

const sliceName = "user"

const userSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    logout: () => {
      Cookie.remove("patient", { domain: config.cookie_path })
      sessionStorage.clear()

      return initialState
    },
    updateUser: (state, action) => {
      Object.assign(state, action.payload)
    },
    resetUnreadMessagesNumber: (state) => {
      Object.assign(state, { unreadMessagesNumber: 0 })
    },
    decrementUnfilledFormsNumber: (state) => {
      Object.assign(state, {
        unfilledFormsNumber: state.unfilledFormsNumber - 1,
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        Object.assign(state, { error: false, loading: true })
      })
      .addCase(getUserProfile.rejected, (state) => {
        Object.assign(state, { error: true, loading: false })
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        Object.assign(state, {
          ...action.payload,
          error: false,
          loading: false,
        })
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
      .addCase(updateUserAvatar.fulfilled, (state, action) => {
        if (action.payload) {
          state.avatarUrl = action.payload
        }
      })
      .addCase(finishOnboarding.fulfilled, (state) => {
        state.onboardingFinished = true
      })
      .addCase(setUserCustomerStatusInactive.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
      .addCase(fetchUserMeetings.fulfilled, (state, action) => {
        Object.assign(state, {
          meeting: { ...action.payload.meeting },
          previousVideoMeeting: { ...action.payload.previousVideoMeeting },
        })
      })
      .addCase(fetchUserNotifications.fulfilled, (state, action) => {
        Object.assign(state, {
          unfilledFormsNumber: action.payload.unfilledFormsNumber,
          unreadMessagesNumber: action.payload.unreadMessagesNumber,
        })
      })
  },
})

export default userSlice

export const formatSwedishPhoneNumber = (phoneNumber) => {
  const regex = /^(0046|\+46)/
  const replacement = "0"
  const modifiedNumber = phoneNumber?.replace(regex, replacement)
  if (modifiedNumber?.length === 10) {
    return `${modifiedNumber.substring(0, 3)} - ${modifiedNumber.substring(
      3,
      6
    )} ${modifiedNumber.substring(6, 8)} ${modifiedNumber.substring(8)}`
  }
  return phoneNumber
}

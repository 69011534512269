import React from "react"
import { AppBar, Container, Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Logo } from "assets"

const BROWSER_HEIGHT = window.innerHeight - 0.1

const useStyles = makeStyles((theme) => ({
  root: (BrowserHeight) => ({
    // minHeight: '100vh',
    position: "relative",
    height: BrowserHeight,
    display: "flex",
    flexDirection: "column",
  }),
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    height: 64,
    padding: "16px 24px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    maxWidth: "100%",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
}))

const EmptyHeader = ({ children }) => {
  const classes = useStyles(BROWSER_HEIGHT)

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <AppBar position="static" className={classes.appBar}>
          <Box className={classes.titleContainer}>
            <Logo />
          </Box>
        </AppBar>
      </div>
      <Container disableGutters className={classes.container}>
        {children}
      </Container>
    </div>
  )
}

export default EmptyHeader

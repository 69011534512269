export function InitVardenSeTrackingCallback(bookingId) {
  if (window.VardenBookingNotification) {
    window.VardenBookingNotification.init({
      sourceSystem: "Din Psykolog",
      careProvider: "1",
      additionalInfo: JSON.stringify({
        bookingId,
        // terapeut: '-',
        // treatment: '-',
      }),
    })
  } else {
    console.warn("VardenBookingNotification does not exist.")
  }
}

import { isDate, isBefore, isSameDay } from "date-fns"

/**
 *
 * @param {string|number} expirationDate
 * @returns {boolean}
 */

function isFreeCardValid(expirationDate) {
  if (!expirationDate) {
    return false
  }
  const endDate = new Date(expirationDate)

  if (!isDate(endDate)) {
    return false
  }

  return isBefore(new Date(), endDate) || isSameDay(new Date(), endDate)
}

export default isFreeCardValid

import { isBefore, parseISO } from "date-fns"
import isEmpty from "lodash.isempty"

import store from "redux/store"
import getUserAge from "utils/getUserAge"
import isFreeCardValid from "utils/isFreeCardValid"
import isPayingCustomer from "utils/isPayingCustomer"
import isActiveSession from "utils/isActiveSession"
import { setUserChatSession } from "redux/chat"
import { setUserCustomerStatusInactive } from "redux/user"
import { getPatientType } from "./getPatientType"

/**
 * This method will create session, change payment status or neither depending on users age, freeCard, or payment status
 *
 * @param {boolean} [shouldCreateSession=true]
 * @returns {Promise<{boolean, boolean?}>}
 */

export default async function chatSessionHandler(shouldCreateSession = true) {
  const { getState, dispatch } = store
  const { chat, caregiver, user } = getState() || {}
  const {
    socialSecurity,
    freeCard,
    customerStatus,
    latestPayment,
    id: userId,
    meeting: videoMeeting,
  } = user || {}
  const { userId: caregiverId } = caregiver?.data || {}
  const { session: lastChatSession } = chat || {}
  const userAge = getUserAge(socialSecurity)
  const isFreeAge = userAge < 20 || userAge >= 85
  const hasValidFreeCard = isFreeCardValid(freeCard?.expirationDate)
  const hasValidPayment = isPayingCustomer(customerStatus)

  // TODO: maybe wrap all help functions and catch if they throw
  // they should throw if provided with incorrect data
  try {
    if (!isEmpty(lastChatSession)) {
      if (isActiveSession(lastChatSession)) {
        return Promise.resolve({ activeSession: true })
      }

      if (
        hasValidPayment &&
        isBefore(parseISO(latestPayment), parseISO(lastChatSession.endedAt))
      ) {
        await dispatch(setUserCustomerStatusInactive()).unwrap()
        return Promise.resolve({ activeSession: false })
      }
    }

    // do not grant chat session if no last session (new user) and user has a booked video meeting
    if (isEmpty(lastChatSession) && videoMeeting?.start) {
      return Promise.resolve({ activeSession: false })
    }

    if (isFreeAge || hasValidFreeCard || hasValidPayment) {
      if (shouldCreateSession) {
        const patientType = getPatientType({
          isFreeAge,
          hasValidFreeCard,
          hasValidPayment,
        })

        await dispatch(
          setUserChatSession({ userId, caregiverId, patientType })
        ).unwrap()
      }

      return Promise.resolve({
        activeSession: true,
        freeAgeOrFreeCardUser: isFreeAge || hasValidFreeCard,
      })
    }

    return Promise.resolve({ activeSession: false })
  } catch (e) {
    return Promise.reject(e)
  }
}

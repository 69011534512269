import React, {
  createRef,
  useEffect,
  useState,
  useCallback,
  useRef,
  Fragment,
} from "react"
import { connect } from "react-redux"
import { isSameDay } from "date-fns"
import { Box, CircularProgress } from "@mui/material"
import { makeStyles } from "@mui/styles"
import DateDivider from "pages/chat/components/DateDivider"
import ChatBubble from "pages/chat/components/ChatBubble"
import useScript from "react-script-hook"
import { defaultPagination } from "utils"
import { setChatMessagesPagination, fetchEntries } from "redux/chat"

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflowY: "scroll",
  },
  wrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  bottomOfChat: {
    height: "16px",
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
}))

const ChatContainer = ({
  chatId,
  messages,
  messagesCount,
  messagesLoading,
  messagesPagination: pagination,
  updateChatMessagesPagination,
  getEntries,
}) => {
  const classes = useStyles()
  const messagesEndRef = createRef()
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const [lastMessageId, setLastMessageId] = useState(null)

  useEffect(() => {
    const lastMessage = messages[messages.length - 1]
    if (lastMessage?.id !== lastMessageId) {
      setLastMessageId(lastMessage?.id)
    }
  }, [messages])

  useEffect(() => {
    if (lastMessageId) {
      messagesEndRef.current.scrollIntoView({
        block: "end",
      })
      setScrolledToBottom(true)
    }
  }, [lastMessageId])

  useEffect(
    () => () => {
      updateChatMessagesPagination(defaultPagination)
      setScrolledToBottom(false)
    },
    []
  )

  useEffect(() => {
    if (pagination.skip) {
      getEntries({ chatId, pagination })
    }
  }, [pagination])

  const observer = useRef()
  const firstUserMessageRef = useCallback(
    (node) => {
      if (messagesLoading) return
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        const hasMore = messagesCount > messages.length
        if (entries[0]?.isIntersecting && scrolledToBottom && hasMore) {
          updateChatMessagesPagination({
            skip: pagination.skip + pagination.limit,
            limit: pagination.limit,
          })
        }
      })
      if (node) observer.current.observe(node)
    },
    [scrolledToBottom, messagesCount, messages]
  )

  const statuspageId =
    process.env.NODE_ENV === "production" ? "dinpsykolog" : "dptest"
  useScript({
    src: `https://${statuspageId}.statuspage.io/embed/script.js`,
    checkForExisting: true,
  })

  const checkFirstUserMessage = (msgs, msgId) => {
    const index = msgs.findIndex((msg) => msg.id === msgId)

    return index === 0
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        {messagesLoading && (
          <Box className={classes.loading}>
            <CircularProgress size={50} color="primary" />
          </Box>
        )}
        {messages.map((message, index) => {
          const { id, createdAt } = message

          const isFirstUserMessage = checkFirstUserMessage(messages, id)

          if (
            index > 0 &&
            isSameDay(
              new Date(messages[index - 1]?.createdAt),
              new Date(createdAt)
            )
          ) {
            return (
              <ChatBubble
                key={id}
                message={message}
                isFirstUserMessage={isFirstUserMessage}
                firstUserMessageRef={firstUserMessageRef}
              />
            )
          }
          return (
            <Fragment key={createdAt}>
              <DateDivider timeStamp={createdAt} />
              <ChatBubble
                key={id}
                message={message}
                isFirstUserMessage={isFirstUserMessage}
                firstUserMessageRef={firstUserMessageRef}
              />
            </Fragment>
          )
        })}
        <div className={classes.bottomOfChat} ref={messagesEndRef} />
      </Box>
    </Box>
  )
}

const mapStateToProps = ({ chat }) => {
  const {
    id: chatId,
    messagesCount,
    messages,
    messagesPagination,
    messagesLoading,
  } = chat

  return {
    chatId,
    messagesCount,
    messages,
    messagesPagination,
    messagesLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateChatMessagesPagination: (pagination) =>
    dispatch(setChatMessagesPagination(pagination)),
  getEntries: (data) => dispatch(fetchEntries(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer)

import React from "react"
import { useController } from "react-hook-form"
import { TextField } from "@mui/material"

const Input = (props) => {
  const { name, control, defaultValue, ...rest } = props
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
  })

  const error = fieldState?.error
  const helperText = error?.message
  const invalid = !!error?.message

  return (
    <TextField
      variant="outlined"
      inputProps={{ "data-testid": `input_${name}` }}
      InputLabelProps={{
        shrink: true,
      }}
      helperText={helperText}
      error={invalid}
      {...field}
      {...rest}
    />
  )
}

export default Input

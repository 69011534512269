import mobileImageStage0 from "assets/mobileTreatmentStage0.svg"
import mobileImageStage1 from "assets/mobileTreatmentStage1.svg"
import mobileImageStage2 from "assets/mobileTreatmentStage2.svg"
import mobileImageStage3 from "assets/mobileTreatmentStage3.svg"
import mobileImageStage4 from "assets/mobileTreatmentStage4.svg"
import desktopImageStage0 from "assets/desktopTreatmentStage0.svg"
import desktopImageStage1 from "assets/desktopTreatmentStage1.svg"
import desktopImageStage2 from "assets/desktopTreatmentStage2.svg"
import desktopImageStage3 from "assets/desktopTreatmentStage3.svg"
import desktopImageStage4 from "assets/desktopTreatmentStage4.svg"

const getTreatmentStageImage = (sessionCount, isMobileView) => {
  if (!sessionCount)
    return isMobileView ? mobileImageStage0 : desktopImageStage0
  if ([1, 2].includes(sessionCount))
    return isMobileView ? mobileImageStage1 : desktopImageStage1
  if ([3, 4, 5, 6].includes(sessionCount))
    return isMobileView ? mobileImageStage2 : desktopImageStage2
  if ([7, 8, 9, 10].includes(sessionCount))
    return isMobileView ? mobileImageStage3 : desktopImageStage3
  if (sessionCount > 10)
    return isMobileView ? mobileImageStage4 : desktopImageStage4
}

export default getTreatmentStageImage

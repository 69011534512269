import Api from "api"

export const imageUpload = (userId, file) => {
  const bodyFormData = new FormData()
  bodyFormData.append("file", file)

  return Api.post(`users/fileupload/${userId}`, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useNavigate } from "react-router-dom"
import { formatSwedishPhoneNumber } from "utils/formatSwedishPhoneNumber"

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      borderRadius: theme.spacing(1.25),
      margin: theme.spacing(2),
      minWidth: "350px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "90%",
      },
      "& .MuiDialogTitle-root": {
        lineHeight: 1.3,
        fontWeight: 600,
      },
      "& .MuiDialogContent-root": {
        padding: theme.spacing(0, 1, 0.5),
      },
      "& .MuiDialogActions-root": {
        justifyContent: "center",
        marginBottom: theme.spacing(2),
      },
    },
  },
  button: {
    borderRadius: theme.spacing(2.5),
    width: 140,
    textTransform: "none",
    fontSize: "0.83rem",
  },
  contactInfoRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 0,
    marginBottom: theme.spacing(2),
    "& p": {
      fontSize: "0.95rem",
    },
  },
  contactInfoTitle: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
  },
  contactInfo: {
    wordBreak: "break-all",
    fontWeight: 500,
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
  },
}))

const VerifyEmailAndPhoneDialog = ({
  onClose,
  openDialog,
  userEmail,
  userPhoneNumber,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <Dialog
      open={openDialog}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          onClose()
        }
      }}
      fullWidth
      maxWidth="xs"
      className={classes.dialog}
    >
      <DialogTitle>Stämmer dina kontaktuppgifter?</DialogTitle>
      <DialogContent>
        <Typography variant="body2" className={classes.contactInfoRow}>
          <Typography
            component="p"
            variant="inherit"
            className={classes.contactInfoTitle}
          >
            E-postadress
          </Typography>
          <Typography
            component="p"
            variant="inherit"
            className={classes.contactInfo}
          >
            {userEmail}
          </Typography>
        </Typography>
        <Typography variant="body2" className={classes.contactInfoRow}>
          <Typography
            component="p"
            variant="inherit"
            className={classes.contactInfoTitle}
          >
            Mobilnummer
          </Typography>
          <Typography
            component="p"
            variant="inherit"
            className={classes.contactInfo}
            sx={{
              color: userPhoneNumber
                ? "inherit"
                : (theme) => theme.palette.warning.main,
            }}
          >
            {userPhoneNumber
              ? formatSwedishPhoneNumber(userPhoneNumber)
              : "behöver fyllas i"}
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => navigate("/profile")}
          className={classes.button}
          color="default"
          sx={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
        >
          Nej, ändra
        </Button>
        <Button
          onClick={onClose}
          className={classes.button}
          variant="contained"
        >
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default VerifyEmailAndPhoneDialog

import { createAsyncThunk } from "@reduxjs/toolkit"

import { getUser } from "api/getUser"
import { imageUpload } from "api/imageUpload"
import { updatePaymentStatus } from "api/updatePaymentStatus"
import { connectToSocket } from "api/connectToSocket"
import { getUserMeetings } from "api/getUserMeetings"

import { showAlert } from "redux/common"
import { customerStatus } from "utils/customerStatus"
import { getUserNotifications } from "api/getUserNotifications"
import { getUserId } from "./selectors"

export const getUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async (id) => {
    const response = await Promise.all([
      getUser(id),
      getUserMeetings(),
      getUserNotifications(id),
    ])
    const [
      { data: userData },
      { data: meetingsData },
      { data: notificationsData },
    ] = response
    return {
      ...userData,
      meeting: {
        ...meetingsData?.meeting,
        start: meetingsData?.meeting?.start
          ? new Date(meetingsData.meeting.start)
          : null,
      },
      previousVideoMeeting: {
        ...meetingsData?.previousVideoMeeting,
        start: meetingsData?.previousVideoMeeting?.start
          ? new Date(meetingsData.previousVideoMeeting.start)
          : null,
      },
      unreadMessagesNumber: notificationsData?.unreadMessagesNumber,
      unfilledFormsNumber: notificationsData?.unfilledFormsNumber,
    }
  }
)

export const getUserAndSocketConnection = createAsyncThunk(
  "user/getUserProfile",
  async (id) => {
    const response = await Promise.all([
      getUser(id),
      getUserMeetings(),
      getUserNotifications(id),
      connectToSocket(),
    ])
    const [
      { data: userData },
      { data: meetingsData },
      { data: notificationsData },
    ] = response
    if (
      !meetingsData?.meeting?.start &&
      !sessionStorage.getItem("navigateToVideoPage")
    ) {
      sessionStorage.setItem("navigateToVideoPage", "true")
    }
    return {
      ...userData,
      meeting: {
        ...meetingsData?.meeting,
        start: meetingsData?.meeting?.start
          ? new Date(meetingsData.meeting.start)
          : null,
      },
      previousVideoMeeting: {
        ...meetingsData?.previousVideoMeeting,
        start: meetingsData?.previousVideoMeeting?.start
          ? new Date(meetingsData.previousVideoMeeting.start)
          : null,
      },
      unreadMessagesNumber: notificationsData?.unreadMessagesNumber,
      unfilledFormsNumber: notificationsData?.unfilledFormsNumber,
    }
  }
)

export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async ({ data, shouldShowAlert }, thunkApi) => {
    try {
      if (shouldShowAlert) {
        thunkApi.dispatch(showAlert({ message: "Profil sparad" }))
      }

      return data
    } catch (e) {
      if (shouldShowAlert) {
        thunkApi.dispatch(
          showAlert({
            type: "error",
            message: "Något gick fel, misslyckades att spara",
          })
        )
      }
    }
  }
)

export const updateUserAvatar = createAsyncThunk(
  "user/updateUserAvatar",
  async (file, thunkApi) => {
    const { userId } = getUserId(thunkApi.getState())

    try {
      const { data } = await imageUpload(userId, file)
      thunkApi.dispatch(showAlert({ message: "Avatar sparad" }))

      return data.url
    } catch (e) {
      const errorMessage =
        e?.response?.data || "Något gick fel med bilduppladdningen"

      thunkApi.dispatch(
        showAlert({
          type: "error",
          message: errorMessage,
        })
      )
    }
  }
)

export const setUserCustomerStatusInactive = createAsyncThunk(
  "user/setUserCustomerStatusInactive",
  async (_, thunkApi) => {
    const { userId } = getUserId(thunkApi.getState())

    try {
      await updatePaymentStatus(userId)

      return { customerStatus: customerStatus.INACTIVE }
    } catch (e) {
      thunkApi.dispatch(showAlert({ type: "error", message: "Något gick fel" }))
      return thunkApi.rejectWithValue(e)
    }
  }
)

export const fetchUserMeetings = createAsyncThunk(
  "user/fetchUserMeetings",
  async () => {
    const { data } = await getUserMeetings()
    const meetingsData = {
      meeting: {
        ...data?.meeting,
        start: data?.meeting?.start ? new Date(data.meeting.start) : null,
      },
      previousVideoMeeting: {
        ...data?.previousVideoMeeting,
        start: data?.previousVideoMeeting?.start
          ? new Date(data.previousVideoMeeting.start)
          : null,
      },
    }
    return meetingsData
  }
)

export const fetchUserNotifications = createAsyncThunk(
  "user/fetchUserNotifications",
  async (userId) => {
    const { data } = await getUserNotifications(userId)
    return data
  }
)

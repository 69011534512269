export default {
  isDev: process.env.REACT_APP_IS_DEV === "true",
  isTest: process.env.REACT_APP_IS_TEST === "true",
  isRc: process.env.REACT_APP_IS_RC === "true",
  company_name: "DinPsykolog",
  redirect_url: process.env.REACT_APP_REDIRECT_URL_AUTH,
  api_url: process.env.REACT_APP_REDIRECT_URL_API,
  ws_url: process.env.REACT_APP_REDIRECT_WS_URL,
  cookie_path: process.env.REACT_APP_COOKIE_PATH,
  kaddioUrl: process.env.REACT_APP_KADDIO_URL,
  stripe_pub_key: process.env.REACT_APP_STRIPE_PUB_KEY,
  faq: "https://dinpsykolog.se/vanliga-fragor/",
  support: "https://dinpsykolog.se/support/",
  payment_info_link: "https://dinpsykolog.se/#vanliga-fragor",
  videoTroubleshootingUrl:
    "https://dinpsykolog.se/om-video-och-ljud-inte-fungerar",
  gtmId: "GTM-KJHD87Q",
  slackErrorsTestChannelId: "C033VE8GVPZ",
  slackErrorsProdChannelId: "C034CBSR0BE",
  wherebyTroubleshootingUrl:
    "https://whereby.helpscoutdocs.com/article/456-troubleshooting",
  timeRequestFormUrl: "https://dinpsykolog.typeform.com/to/HY64FUsL",
  braiveLoginUrl: "https://app.braive.com/en/?language=sv",
  postHogKey: process.env.REACT_APP_POSTHOG_KEY,
  postHogHost: process.env.REACT_APP_POSTHOG_HOST,
  abTestFeatureFlag: "matchmaking-filter-shown",
  abTestGroupName: "test",
}

export const getPatientType = ({
  isFreeAge,
  hasValidFreeCard,
  hasValidPayment,
}) => {
  const patientTypes = {
    1: isFreeAge,
    2: hasValidFreeCard,
    3: hasValidPayment,
  }
  return Object.keys(patientTypes).find((key) => patientTypes[key])
}

import React from "react"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "26px",
    height: "26px",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    padding: "0",
    zindex: "10",
  },
  span: ({ open }) => ({
    position: "relative",
    width: "27px",
    height: "3px",
    background: theme.palette.primary.main,
    borderRadius: "10px",
    transition: "all 0.2s linear",
    transformOrigin: "2px",
    "&:first-child": {
      transform: open ? "rotate(46deg)" : "rotate(0)",
    },
    "&:nth-child(2)": {
      opacity: open ? "0" : "1",
      transform: open ? "translateX(-20px)" : "translateX(0)",
    },
    "&:nth-child(3)": {
      transform: open ? "rotate(-46deg)" : "rotate(0)",
    },
  }),
}))

const BurgerMenuIcon = ({ open }) => {
  const classes = useStyles({ open })

  return (
    <div className={classes.root}>
      <span className={classes.span} />
      <span className={classes.span} />
      <span className={classes.span} />
    </div>
  )
}

export default BurgerMenuIcon

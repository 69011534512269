/* eslint-disable no-nested-ternary */
import { useState } from "react"
import {
  Box,
  Typography,
  Link as LinkButton,
  useMediaQuery,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { connect } from "react-redux"
import { useTheme } from "@emotion/react"
import { getFirstLetter } from "utils/getFirstLetter"
import { getFormattedDateAndTime } from "utils/getFormattedDateAndTime"
import { fetchUserMeetings as getUserMeetings } from "redux/user"
import { showAlert as displayAlert } from "redux/common"
import { unmatchCaregiver } from "api/unmatchCaregiver"
import { cancelMeeting } from "api/cancelMeeting"
import DefaultAvatar from "./DefaultAvatar"
import CancelMeetingConfirmationDialog from "./CancelMeetingConfirmationDialog"

const useStyles = makeStyles((theme) => ({
  meetingBanner: {
    width: "100%",
    height: "67px",
    background: "#F7F7F7",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  caregiverContainer: {
    display: "flex",
  },
  meetingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "end",
  },
  caregiverInfo: {
    margin: theme.spacing(0, 2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down(330)]: {
      margin: theme.spacing(0, 1),
    },
  },
  textSpacing: {
    marginTop: 0,
  },
  text: {
    color: theme.palette.dark_gray,
    textDecoration: "none",
    fontSize: "0.9rem",
    [theme.breakpoints.down(330)]: {
      fontSize: "0.8rem",
    },
  },
  linkButton: {
    "& span": {
      fontSize: "0.9rem",
      textDecoration: "underline",
      [theme.breakpoints.down(330)]: {
        fontSize: "0.8rem",
      },
    },
  },
  row: {
    display: "flex",
  },
  name: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "0.9rem",
    [theme.breakpoints.down(330)]: {
      fontSize: "0.8rem",
    },
  },
  nextBookedTimeText: {
    fontSize: "0.9rem",
    [theme.breakpoints.down(330)]: {
      fontSize: "0.8rem",
    },
  },
  nextBookedTime: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    marginLeft: theme.spacing(0.25),
    [theme.breakpoints.down(330)]: {
      fontSize: "0.8rem",
    },
  },
}))

const CancelMeetingBanner = ({
  userId,
  meeting,
  avatarUrl,
  firstName,
  lastName,
  fetchUserMeetings,
  previousVideoMeeting,
  showAlert,
}) => {
  const classes = useStyles()
  const videoMeetingFormattedStart = meeting?.start
    ? getFormattedDateAndTime(meeting.start)
    : null
  const [
    openCancelMeetingConfirmationDialog,
    setOpenCancelMeetingConfirmationDialog,
  ] = useState(false)
  const [meetingCancellationError, setMeetingCancellationError] = useState(null)
  const theme = useTheme()
  const isSmallDevice = useMediaQuery(theme.breakpoints.down(330))

  const handleMeetingCancellation = async ({ isLateCancellation }) => {
    try {
      await cancelMeeting(meeting?.id)
      setOpenCancelMeetingConfirmationDialog(false)

      if (!previousVideoMeeting?.start) {
        handleUnmatchFromCaregiver()
      } else {
        await fetchUserMeetings()
      }

      const afterMeetingCancellationMessage = isLateCancellation
        ? "Ditt videosamtal har avbokats. Du är välkommen att boka en ny tid."
        : meeting?.payingUser
        ? "Ditt videosamtal har avbokats och din patientavgift kommer återbetalas. Du är välkommen att boka en ny tid."
        : "Ditt videosamtal har avbokats. Du är välkommen att boka en ny tid."
      showAlert({
        type: "success",
        message: afterMeetingCancellationMessage,
      })
    } catch (error) {
      setMeetingCancellationError(
        "Något gick fel, vänligen försök igen inom kort. Om problemen består, kontakta info@dinpsykolog.se."
      )
    }
  }
  const handleUnmatchFromCaregiver = async () => {
    await unmatchCaregiver(userId)
    window.location.replace("/")
  }
  if (meeting && meeting.start && meeting.paid) {
    return (
      <Box className={classes.meetingBanner}>
        <Box className={classes.caregiverContainer}>
          <DefaultAvatar
            size={isSmallDevice ? 35 : 48}
            src={avatarUrl}
            name={`${firstName} ${getFirstLetter(lastName)}`}
          />
          <Box className={classes.caregiverInfo}>
            <Typography
              className={`${classes.textSpacing} ${classes.name}`}
            >{`${firstName} ${getFirstLetter(lastName)}`}</Typography>
            <Typography className={classes.text}>Leg. Psykolog</Typography>
          </Box>
        </Box>
        <Box className={classes.meetingContainer}>
          <Box className={classes.row}>
            <Typography className={classes.nextBookedTimeText}>
              Bokat:
            </Typography>
            <Typography className={classes.nextBookedTime}>
              {videoMeetingFormattedStart || "Ingen bokad tid"}
            </Typography>
          </Box>
          <LinkButton
            component="button"
            className={classes.linkButton}
            onClick={() => setOpenCancelMeetingConfirmationDialog(true)}
          >
            <Typography variant="caption">Avboka</Typography>
          </LinkButton>
        </Box>
        <CancelMeetingConfirmationDialog
          openDialog={openCancelMeetingConfirmationDialog}
          onClose={() => {
            setOpenCancelMeetingConfirmationDialog(false)
            setMeetingCancellationError(null)
          }}
          onMeetingCancellation={handleMeetingCancellation}
          error={meetingCancellationError}
          videoMeetingStart={meeting?.start}
        />
      </Box>
    )
  }

  return null
}

const mapStateToProps = (state) => {
  const { user, caregiver } = state
  const { meeting, previousVideoMeeting, userId } = user
  const { avatarUrl, firstName, lastName } = caregiver?.data || {}

  return {
    userId,
    meeting,
    previousVideoMeeting,
    avatarUrl,
    firstName,
    lastName,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUserMeetings: () => dispatch(getUserMeetings()),
  showAlert: (data) => dispatch(displayAlert(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CancelMeetingBanner)

import Cookie from "js-cookie"
import config from "config"

export const setCookie = (name, data, expires) => {
  const domain = config.cookie_path
  const secure = !config.isDev
  const sameSite = config.isDev ? "" : "None"

  Cookie.set(name, data, {
    domain,
    secure,
    sameSite,
    expires,
  })
}

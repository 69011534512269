import React, { useState, useEffect } from "react"
import isAfter from "date-fns/isAfter"
import LoadingScreen from "components/LoadingScreen"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import theme from "theme"
import { CssBaseline } from "@mui/material"
import Cookie from "js-cookie"
import { setCookie } from "utils/setCookie"

const checkIfOldBuildDate = (
  latestBuildDateInMilliseconds,
  latestCacheDeletionDateInMilliseconds
) => {
  const latestBuildDate = new Date(latestBuildDateInMilliseconds)
  const latestCacheDeletionDate = latestCacheDeletionDateInMilliseconds
    ? new Date(latestCacheDeletionDateInMilliseconds)
    : null

  return (
    !latestCacheDeletionDate ||
    isAfter(latestBuildDate, latestCacheDeletionDate)
  )
}

const deleteCacheAndReload = async (
  latestBuildDateInMilliseconds,
  setIsLatestBuildDate
) => {
  try {
    // Delete cache and reload the page
    const { caches } = window
    const cacheNames = await caches.keys()
    for (const cacheName of cacheNames) {
      await caches.delete(cacheName)
    }
    const year = 365
    setCookie(
      "latestCacheDeletionDatePatient",
      latestBuildDateInMilliseconds,
      year
    )
    console.log("Cache deleted")
    window.location.reload()
  } catch (error) {
    console.error("Error while deleting cache: ", error)
    setIsLatestBuildDate(true)
  }
}

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false)

    useEffect(() => {
      fetch("/meta.json", {
        headers: {
          "Cache-Control": "no-store",
          Pragma: "no-cache",
          Expires: 0,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((meta) => {
          const latestBuildDateInMilliseconds = meta.buildDate
          const latestCacheDeletionDateFromCookie = Cookie.get(
            "latestCacheDeletionDatePatient"
          )
          const latestCacheDeletionDateInMilliseconds =
            latestCacheDeletionDateFromCookie
              ? parseInt(latestCacheDeletionDateFromCookie)
              : null
          const shouldForceRefresh =
            window?.caches &&
            checkIfOldBuildDate(
              latestBuildDateInMilliseconds,
              latestCacheDeletionDateInMilliseconds
            )
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false)
            console.log("Should delete cache")
            deleteCacheAndReload(
              latestBuildDateInMilliseconds,
              setIsLatestBuildDate
            )
          } else {
            setIsLatestBuildDate(true)
            console.log("Should not delete cache")
          }
        })
    }, [])

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {isLatestBuildDate ? <Component {...props} /> : <LoadingScreen />}
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }

  return ClearCacheComponent
}

export default withClearCache

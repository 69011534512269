import { compareAsc, parseISO } from "date-fns"
import { createAsyncThunk } from "@reduxjs/toolkit"

import { showAlert } from "redux/common"
import { createChatSession } from "api/createChatSession"
import { getChatSessions } from "api/getSessions"
import { getUserChat } from "api/getUserChat"
import { getCaregiver } from "api/getCaregiver"
import { getChatEntries } from "api/getChatEntries"
import { setCaregiver, getCaregiverId } from "redux/caregiver"
import { postChatEntry } from "api/postChatEntry"
import { postMessageInChat } from "."

export const setUserChatSession = createAsyncThunk(
  "chat/setUserChatSession",
  async ({ userId, caregiverId, patientType }, thunkApi) => {
    try {
      const { data: session } = await createChatSession(userId, caregiverId, {
        patientType,
      })

      return session
    } catch (e) {
      thunkApi.dispatch(showAlert({ type: "error", message: "Något gick fel" }))
      return thunkApi.rejectWithValue(e)
    }
  }
)

export const fetchUserChatSession = createAsyncThunk(
  "chat/fetchUserChatSession",
  async ({ userId, ...params }, thunkApi) => {
    try {
      const { data } = await getChatSessions(userId, {
        ...params,
      })
      const { caregiverId: matchedCaregiverId } = getCaregiverId(
        thunkApi.getState()
      )
      let sessionsWithMatchedCaregiver
      if (data?.length && matchedCaregiverId) {
        sessionsWithMatchedCaregiver = data.filter(
          ({ caregiverId }) => caregiverId === matchedCaregiverId
        )
      }

      return sessionsWithMatchedCaregiver?.length
        ? sessionsWithMatchedCaregiver[0]
        : null
    } catch (e) {
      thunkApi.rejectWithValue(e)
    }
  }
)

export const fetchUserChat = createAsyncThunk(
  "chat/fetchUserChat",
  async (userId, thunkApi) => {
    try {
      const {
        data: [{ id: chatId }],
      } = await getUserChat(userId)

      const { data: caregiver } = await getCaregiver(userId)

      thunkApi.dispatch(
        setCaregiver({
          loading: false,
          data: caregiver,
          error: false,
        })
      )

      const response = await getChatEntries(chatId)
      const { status: chatEntriesStatus, ...chatEntriesRest } = response

      const {
        data: { data: chatEntries, totalCount: messagesCount },
      } = chatEntriesRest
      const chatEntriesCopy = [...chatEntries]

      const sortedMessages = chatEntriesCopy.sort((a, b) =>
        compareAsc(parseISO(a.createdAt), parseISO(b.createdAt))
      )

      return {
        id: chatId,
        messages: sortedMessages,
        messagesCount,
      }
    } catch (e) {
      thunkApi.dispatch(
        setCaregiver({
          loading: false,
          error: true,
          data: {},
        })
      )

      thunkApi.rejectWithValue(e)
    }
  }
)

export const fetchEntries = createAsyncThunk(
  "chat/fetchEntries",
  async ({ chatId, pagination }, thunkApi) => {
    try {
      const { data } = await getChatEntries(chatId, pagination)

      return data
    } catch (e) {
      thunkApi.rejectWithValue(e)
    }
  }
)

export const postMessage = createAsyncThunk(
  "chat/postMessage",
  async ({ chatId, ...body }, thunkApi) => {
    try {
      const {
        data: [messageData],
      } = await postChatEntry({ ...body, chatId })

      const chatMessage = { ...messageData, chatId, ...body }

      thunkApi.dispatch(postMessageInChat(chatMessage))

      return chatMessage
    } catch (e) {
      thunkApi.dispatch(
        showAlert({
          type: "error",
          message:
            "Ditt meddelande gick tyvärr inte att skicka, försök gärna igen",
        })
      )
      return thunkApi.rejectWithValue(e)
    }
  }
)

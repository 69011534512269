import React, { useState, useEffect } from "react"
import { Box, Hidden, CircularProgress, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { navigationValues } from "utils/navigationValues"
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded"

const IMAGE_SIZE = 60
const OUTER_BORDER = 5
const INNER_BORDER = 2

const useStyles = makeStyles((theme) => ({
  "@keyframes outer-border-pulsate": {
    "0%": {
      borderWidth: OUTER_BORDER,
    },
    "50%": {
      borderWidth: OUTER_BORDER * 4,
    },
    "100%": {
      borderWidth: OUTER_BORDER,
    },
  },
  "@keyframes inner-border-pulsate": {
    "0%": {
      borderWidth: INNER_BORDER,
    },
    "50%": {
      borderWidth: INNER_BORDER * 4,
    },
    "100%": {
      borderWidth: INNER_BORDER,
    },
  },
  "@-webkit-keyframes outer-border-pulsate": {
    "0%": {
      borderWidth: OUTER_BORDER,
    },
    "50%": {
      borderWidth: OUTER_BORDER * 4,
    },
    "100%": {
      borderWidth: OUTER_BORDER,
    },
  },
  "@-webkit-keyframes inner-border-pulsate": {
    "0%": {
      borderWidth: INNER_BORDER,
    },
    "50%": {
      borderWidth: INNER_BORDER * 4,
    },
    "100%": {
      borderWidth: INNER_BORDER,
    },
  },
  root: {
    position: "fixed",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  wrapper: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: IMAGE_SIZE + OUTER_BORDER * 4 * 2 + INNER_BORDER * 4 * 2,
    width: IMAGE_SIZE + OUTER_BORDER * 4 * 2 + INNER_BORDER * 4 * 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  caregiverOuterCircle: {
    display: "block",
    borderRadius: "100%",
    animation: "$outer-border-pulsate 2s infinite",
    "-webkit-animation": "$outer-border-pulsate 2s infinite",
    borderWidth: OUTER_BORDER,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
  caregiverInnerCircle: {
    display: "block",
    borderRadius: "100%",
    animation: "$inner-border-pulsate 2s infinite",
    "-webkit-animation": "$inner-border-pulsate 2s infinite",
    borderWidth: INNER_BORDER,
    borderStyle: "solid",
    borderColor: "#fff",
    "& .MuiAvatar-root": {
      backgroundSize: "contain",
    },
  },
  text: {
    position: "fixed",
    marginTop: "0.75rem",
    top: "65%",
    fontSize: "1.1rem",
    textAlign: "center",
    width: "90vw",
  },
  heartIcon: {
    width: "120px",
    height: "120px",
    padding: theme.spacing(2, 0, 1),
  },
}))

const getLoadingText = (navigationValue) => {
  switch (navigationValue) {
    case navigationValues.HOME:
      return "Laddar ditt hem..."
    case navigationValues.VIDEO:
      return "Laddar..."
    case navigationValues.MESSAGES:
      return "Laddar meddelanden..."
    default:
      return "Laddar..."
  }
}

const LoadingScreen = ({ screen }) => {
  const classes = useStyles()
  const [loadingText, setLoadingText] = useState(getLoadingText(screen))

  useEffect(() => {
    let intervalCount = 0
    const interval = setInterval(() => {
      intervalCount += 1
      if (intervalCount === 2) {
        setLoadingText("Ursäkta dröjsmålet, laddas strax…")
        clearInterval(interval)
      } else {
        setLoadingText("Var god vänta…")
      }
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Box className={classes.root}>
      <Hidden smDown>
        <CircularProgress size={50} color="primary" />
      </Hidden>
      <Hidden smUp>
        <Box className={classes.wrapper}>
          <Box className={classes.caregiverOuterCircle}>
            <Box className={classes.caregiverInnerCircle}>
              <FavoriteRoundedIcon
                color="primary"
                className={classes.heartIcon}
              />
            </Box>
          </Box>
        </Box>
        <Typography className={classes.text}>{loadingText}</Typography>
      </Hidden>
    </Box>
  )
}

export default LoadingScreen

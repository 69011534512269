import React from "react"
import { connect } from "react-redux"
import { Box, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import DefaultAvatar from "components/DefaultAvatar"
import { getFirstLetter } from "utils/getFirstLetter"

const CaregiverImage = ({ avatarUrl, firstName, lastName }) => {
  const theme = useTheme()
  const IMAGE_SIZE = useMediaQuery(theme.breakpoints.down("sm")) ? 130 : 150

  return (
    <Box>
      <DefaultAvatar
        size={IMAGE_SIZE}
        src={avatarUrl}
        name={`${firstName} ${getFirstLetter(lastName)}`}
      />
    </Box>
  )
}

const mapStateToProps = (state) => {
  const { caregiver } = state
  const { avatarUrl, firstName, lastName } = caregiver?.data || {}

  return { avatarUrl, firstName, lastName }
}

export default connect(mapStateToProps)(CaregiverImage)

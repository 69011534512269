import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import isEmpty from "lodash.isempty"
import { useSessionStorage } from "utils/useSessionStorage"
import "emoji-mart/css/emoji-mart.css"
import { Picker } from "emoji-mart"
import { Box, IconButton, TextareaAutosize } from "@mui/material"
import { makeStyles } from "@mui/styles"
import {
  Send as SendIcon,
  SendOutlined as SendOutlinedIcon,
  SentimentSatisfiedAlt as SentimentSatisfiedIcon,
  SentimentVerySatisfied as SentimentVerySatisfiedIcon,
} from "@mui/icons-material/"
import { postMessage } from "redux/chat"
import { selectUserData } from "redux/user"
import { BrowserView, isDesktop, isIOS } from "react-device-detect"
import { entryType } from "utils/entryType"
import { dataType } from "utils/dataType"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    padding: theme.spacing(2),
    alignItems: "center",
    backgroundColor: theme.palette.lighter_gray,
    borderTop: `0.5px solid ${theme.palette.gray}`,
    position: "relative",
  },
  input: {
    display: "flex",
    flexGrow: 1,
    borderRadius: 12.5,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    lineHeight: 1.4,
    border: `0.5px solid ${theme.palette.gray}`,
    fontFamily: `${theme.typography.fontFamily}`,
    fontWeight: 400,
    fontSize: "1rem",
    [theme.breakpoints.down(390)]: {
      fontSize: "0.9375rem",
    },
    [theme.breakpoints.down(370)]: {
      fontSize: "0.875rem",
      lineHeight: 1.5,
    },
    "&:focus": {
      outline: "none",
    },
    resize: "none",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 110px)",
    },
  },
  sendButton: {
    padding: 0,
    marginLeft: theme.spacing(2),
    "& svg": {
      fontSize: 35,
      color: theme.palette.primary.main,
    },
    "& svg:first-child": {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      "&:hover, &:active, &:focus": {
        backgroundColor: "transparent",
        "& svg:first-child": {
          display: "block",
        },
        "& svg:not(:first-child)": {
          display: "none",
        },
      },
    },
    "&:disabled": {
      "& svg": {
        color: theme.palette.gray,
      },
      "& svg:first-child": {
        display: "none !important",
      },
      "& svg:not(:first-child)": {
        display: "block !important",
      },
    },
  },
  emojiContainer: {
    position: "relative",
  },
  emojiPicker: {
    position: "absolute",
    bottom: "47px",
    right: "-60px",
  },
  emojiButton: {
    padding: "4px 5px",
    minWidth: "30px",
    marginLeft: theme.spacing(2),
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  "@global": {
    ".emoji-mart": {
      "&:after": {
        content: "''",
        width: "15px",
        height: "15px",
        transform: "rotate(-45deg)",
        background: "#fff",
        position: "absolute",
        bottom: "-7px",
        right: "72px",
        borderBottom: "1px solid #d9d9d9",
        borderLeft: "1px solid #d9d9d9",
      },
      "& .emoji-mart-scroll": {
        height: "285px",
      },
    },
    ".emoji-mart-preview": {
      display: "none",
    },
    ".emoji-mart-bar:last-child": {
      minHeight: "10px",
    },
  },
}))

const ChatBar = ({ sendMessage, caregiver, userId, chat: { id } }) => {
  const classes = useStyles()
  const [latestMessage, setLatestMessage] = useSessionStorage("latestMessage")
  const {
    data: { id: caregiverId },
  } = caregiver

  const { control, handleSubmit, reset, watch, setValue, register, formState } =
    useForm({
      reValidateMode: "onSubmit",
      defaultValue: {
        message: latestMessage,
      },
    })

  const [disabledSubmitButton, setDisabledSubmitButton] = useState(
    isEmpty(latestMessage?.trim())
  )
  const [isHoveredEmojiButton, setIsHoveredEmojiButton] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const messageRef = useRef(null)
  const { ref } = register("message")
  const emojiPickerRef = useRef(null)
  const emojiButtonRef = useRef(null)
  const [cursorPosition, setCursorPosition] = useState(null)
  const { isSubmitting } = formState

  useEffect(() => {
    if (isDesktop) {
      const handleClickOutsideEmojiPicker = (event) => {
        const isClickInsideEmojiPicker =
          emojiPickerRef.current &&
          emojiPickerRef.current.contains(event.target)
        const isEmojiButtonClick =
          emojiButtonRef.current &&
          emojiButtonRef.current.contains(event.target)
        if (!isClickInsideEmojiPicker && !isEmojiButtonClick) {
          setShowEmojiPicker(false)
          setIsHoveredEmojiButton(false)
        }
      }
      document.addEventListener("click", handleClickOutsideEmojiPicker)
      return () => {
        document.removeEventListener("click", handleClickOutsideEmojiPicker)
      }
    }
  }, [])

  const onSubmit = (data) =>
    new Promise(() => {
      setDisabledSubmitButton(true)
      const { message } = data

      if (!isIOS) {
        messageRef.current.focus()
      }

      if (!isEmpty(message?.trim())) {
        const date = new Date()

        sendMessage({
          chatId: id,
          userId,
          data: message,
          dataType: dataType.TEXT,
          fromUserId: userId,
          toUserId: caregiverId,
          createdAt: date.toISOString(),
          seen: false,
          type: entryType.DEFAULT,
        })
          .then(() => {
            reset({ message: "" })
          })
          .catch(() => {
            setDisabledSubmitButton(false)
          })
      }
    })

  watch(({ message }) => {
    setDisabledSubmitButton(isEmpty(message?.trim()))
    setLatestMessage(message)
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.root}>
        <Controller
          name="message"
          defaultValue={latestMessage}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextareaAutosize
              className={classes.input}
              placeholder="OBS! Skriv endast om tidbokning."
              autoComplete="off"
              minRows={1}
              maxRows={8}
              maxLength={2000}
              onKeyDown={(e) =>
                e.keyCode === 13 &&
                (e.metaKey || e.ctrlKey) &&
                handleSubmit(onSubmit)()
              }
              value={field.value}
              ref={(e) => {
                ref(e)
                messageRef.current = e
              }}
              onChange={field.onChange}
              onBlur={() => {
                setCursorPosition(messageRef.current.selectionStart)
              }}
            />
          )}
        />
        <BrowserView>
          <div className={classes.emojiContainer}>
            <IconButton
              ref={emojiButtonRef}
              onMouseEnter={() => setIsHoveredEmojiButton(true)}
              onMouseLeave={() => setIsHoveredEmojiButton(showEmojiPicker)}
              id="emoji-btn"
              className={classes.emojiButton}
              onClick={() => {
                setShowEmojiPicker(!showEmojiPicker)
              }}
            >
              {isHoveredEmojiButton ? (
                <SentimentVerySatisfiedIcon
                  style={{ fontSize: 28 }}
                  color="primary"
                />
              ) : (
                <SentimentSatisfiedIcon
                  style={{ fontSize: 28 }}
                  color="primary"
                />
              )}
            </IconButton>
            {showEmojiPicker && (
              <Box
                className={`${classes.emojiPicker} ph-no-capture`}
                ref={emojiPickerRef}
              >
                <Picker
                  exclude={["flags"]}
                  enableFrequentEmojiSort
                  emojiTooltip
                  showPreview={false}
                  onSelect={(emoji) => {
                    if (typeof cursorPosition === "number") {
                      setValue(
                        "message",
                        latestMessage.substring(0, cursorPosition) +
                          emoji.native +
                          latestMessage.substring(cursorPosition)
                      )
                      setCursorPosition(cursorPosition + emoji.native.length)
                    } else {
                      setValue("message", latestMessage + emoji.native)
                    }
                  }}
                />
              </Box>
            )}
          </div>
        </BrowserView>
        <IconButton
          disabled={disabledSubmitButton || isSubmitting}
          type="submit"
          className={classes.sendButton}
        >
          <SendOutlinedIcon />
          <SendIcon />
        </IconButton>
      </Box>
    </form>
  )
}

const mapStateToProps = (state) => {
  const { id: userId } = selectUserData(state)
  const { caregiver, chat } = state

  return {
    caregiver,
    chat,
    userId,
  }
}

const mapDispatchToProps = {
  sendMessage: postMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatBar)

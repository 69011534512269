import React, { useEffect, useState } from "react"
import { beforeInstallPromptContext as BeforeInstallPromptContext } from "contexts"

const BeforeInstallPromptListenerWrapper = ({ children }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null)

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault()
      setDeferredPrompt(e)
    }
    window.addEventListener("beforeinstallprompt", handler)

    return () => window.removeEventListener("beforeinstallprompt", handler)
  }, [])

  return (
    <BeforeInstallPromptContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ deferredPrompt, setDeferredPrompt }}
    >
      {children}
    </BeforeInstallPromptContext.Provider>
  )
}

export default BeforeInstallPromptListenerWrapper

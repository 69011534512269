import { createSelector } from "@reduxjs/toolkit"

const selectUserState = (state) => state.user

const getUserId = createSelector(selectUserState, (user) => ({
  userId: user?.id,
}))

const selectUserFullName = createSelector(selectUserState, (user) => {
  const { firstName, lastName } = user

  return `${firstName} ${lastName}`
})

const selectUserData = createSelector(selectUserState, (user) => {
  const { error, loading, ...rest } = user || {}

  return rest
})

export { selectUserState, getUserId, selectUserData, selectUserFullName }

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  payButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    opacity: "0.8",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      opacity: "1",
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
  },
}))

const ReservedTimeRejectionConfirmationDialog = ({
  onClose,
  openDialog,
  openPaymentDialog,
}) => {
  const classes = useStyles()
  return (
    <Dialog open={openDialog} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Är du säker att du inte vill ha tiden?</DialogTitle>
      <DialogContent>
        Din tid kommer försvinna om du inte betalar för den.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className={classes.closeButton}
          color="default"
        >
          Jag är säker
        </Button>
        <Button
          onClick={() => openPaymentDialog()}
          className={classes.payButton}
        >
          Betala nu
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReservedTimeRejectionConfirmationDialog

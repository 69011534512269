import { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  Paper,
  BottomNavigation as Navigation,
  BottomNavigationAction as NavigationAction,
  Badge,
} from "@mui/material"
import {
  Home as HomeIcon,
  HomeOutlined as HomeOutlinedIcon,
  Mail as MailIcon,
  MailOutline as MailOutlineIcon,
  Videocam as VideocamIcon,
  VideocamOutlined as VideocamOutlinedIcon,
} from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import { useNavigate, useLocation } from "react-router-dom"
import { navigationValues } from "utils/navigationValues"

const useStyles = makeStyles((theme) => ({
  navigation: {
    borderTop: `1px solid ${theme.palette.gray}`,
    height: "60px",
    "& .MuiBottomNavigationAction-label.Mui-selected": {
      fontSize: "0.75rem",
      fontWeight: "bold",
    },
    "& .MuiBottomNavigationAction-root": {
      paddingTop: theme.spacing(1.25),
    },
  },
  badge: {
    "& .MuiBadge-badge": {
      height: "13px",
      minWidth: "13px",
      width: "13px",
      color: theme.palette.white,
      backgroundColor: theme.palette.error.main,
      fontWeight: 600,
      fontSize: "0.51rem",
    },
  },
}))

const BottomNavigation = ({ unreadMessagesNumber, unfilledFormsNumber }) => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const [navigationValue, setNavigationValue] = useState(null)
  const shouldBadgeDisplay = unreadMessagesNumber || unfilledFormsNumber

  useEffect(() => {
    const path = location.pathname
    switch (path) {
      case "/":
        setNavigationValue(navigationValues.HOME)
        break
      case "/video":
        setNavigationValue(navigationValues.VIDEO)
        break
      case "/messages":
        setNavigationValue(navigationValues.MESSAGES)
        break
      default:
        setNavigationValue(null)
        break
    }
  }, [location])

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <Navigation
        showLabels
        value={navigationValue}
        onChange={(event, newValue) => {
          setNavigationValue(newValue)
          switch (newValue) {
            case navigationValues.VIDEO:
              navigate("/video")
              break
            case navigationValues.MESSAGES:
              navigate("/messages")
              break
            default:
              navigate("/")
              break
          }
        }}
        className={classes.navigation}
      >
        <NavigationAction
          disableRipple
          label="Hem"
          icon={
            navigationValue === navigationValues.HOME ? (
              <HomeIcon />
            ) : (
              <HomeOutlinedIcon />
            )
          }
        />
        <NavigationAction
          disableRipple
          label="Video"
          icon={
            navigationValue === navigationValues.VIDEO ? (
              <VideocamIcon />
            ) : (
              <VideocamOutlinedIcon />
            )
          }
        />
        <NavigationAction
          disableRipple
          label="Meddelanden"
          icon={
            navigationValue === navigationValues.MESSAGES ? (
              <Badge
                badgeContent={shouldBadgeDisplay ? 1 : 0}
                className={classes.badge}
              >
                <MailIcon />
              </Badge>
            ) : (
              <Badge
                badgeContent={shouldBadgeDisplay ? 1 : 0}
                className={classes.badge}
              >
                <MailOutlineIcon />
              </Badge>
            )
          }
        />
      </Navigation>
    </Paper>
  )
}

function mapStateToProps(state) {
  const { user } = state
  const { unreadMessagesNumber, unfilledFormsNumber } = user

  return { unreadMessagesNumber, unfilledFormsNumber }
}

export default connect(mapStateToProps)(BottomNavigation)

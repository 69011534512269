import { createAsyncThunk } from "@reduxjs/toolkit"

import { matchCaregiverAndPatient } from "api/matchCaregiverAndPatient"
import { getRecommendedCaregivers } from "api/getRecommendedCaregivers"
import { showAlert } from "redux/common"

export const finishOnboarding = createAsyncThunk(
  "onboarding/finishOnboarding",
  async (caregiverId, thunkApi) => {
    try {
      await matchCaregiverAndPatient(caregiverId)
    } catch (e) {
      thunkApi.dispatch(
        showAlert({
          type: "error",
          message: "Något gick fel",
        })
      )
      return thunkApi.rejectWithValue(e)
    }
  }
)

export const getCaregivers = createAsyncThunk(
  "onboarding/getCaregivers",
  async ({
    userId,
    sortBy = "recommended",
    filterDays = [],
    filterHours = [],
  }) => {
    const { data: recommended } = await getRecommendedCaregivers(
      userId,
      sortBy,
      filterDays,
      filterHours
    )

    return recommended
  }
)

import { createSlice } from "@reduxjs/toolkit"
import { getCaregivers } from "./actions"

const initialState = {
  chooseCaregiver: {
    caregivers: null,
    caregiversFetchTime: 0,
    error: false,
    loading: false,
  },
}

const sliceName = "onboarding"

const onboardingSlice = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCaregivers.pending, (state) => {
        Object.assign(state, {
          chooseCaregiver: {
            caregivers: null,
            caregiversFetchTime: 0,
            error: false,
            loading: true,
          },
        })
      })
      .addCase(getCaregivers.rejected, (state) => {
        Object.assign(state, {
          chooseCaregiver: {
            caregivers: null,
            caregiversFetchTime: 0,
            error: true,
            loading: false,
          },
        })
      })
      .addCase(getCaregivers.fulfilled, (state, action) => {
        const chooseCaregiver = {
          caregivers: action.payload,
          caregiversFetchTime: Date.now(),
          error: false,
          loading: false,
        }

        Object.assign(state, { chooseCaregiver })
      })
  },
})

export default onboardingSlice

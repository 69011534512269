import { customerStatus } from "./customerStatus"

/**
 * Check if parameter is equal to string "paying_customer"
 *
 * @param {*} status
 * @returns {boolean}
 */

function isPayingCustomer(status) {
  return status === customerStatus.PAYING
}

export default isPayingCustomer

import React from "react"
import { connect } from "react-redux"
import {
  Box,
  Hidden,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import ChangeAvatar from "components/ChangeAvatar"
import TouchButton from "components/TouchButton"
import {
  ArrowBack as ArrowBackIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material/"
import { getUserProfile as fetchUserProfile } from "redux/user"
import ProfileForm from "./components/Form"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  profile: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  container: {
    backgroundColor: theme.palette.lighter_gray,
  },
  loadingContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.lighter_gray,
  },
  icon: {
    height: 200,
    width: 200,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: 150,
      width: 150,
    },
  },
  errorButton: {
    marginTop: theme.spacing(2),
  },
}))

const Profile = ({ getUserProfile, loading, error, userId }) => {
  const classes = useStyles()

  // eslint-disable-next-line react/no-unstable-nested-components
  const HandleLoading = () => {
    if (loading) {
      return (
        <Box className={classes.loadingContainer}>
          <CircularProgress color="primary" />
        </Box>
      )
    }

    if (error) {
      return (
        <Box className={classes.loadingContainer}>
          <ErrorOutlineIcon color="primary" className={classes.icon} />
          <Typography variant="h4">Något gick fel!</Typography>
          <Button
            variant="contained"
            onClick={() => getUserProfile(userId)}
            className={classes.errorButton}
          >
            Försök igen
          </Button>
        </Box>
      )
    }

    return null
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.profile}>
        <Hidden smDown>
          <Box mx={2} mt={2}>
            <TouchButton to="/">
              <ArrowBackIcon />
              <Box ml={1}>
                <Typography color="primary">Tillbaka hem</Typography>
              </Box>
            </TouchButton>
            <Box ml={2} mt={2}>
              <Typography color="primary" variant="h6">
                Min Profil
              </Typography>
            </Box>
          </Box>
        </Hidden>
        <HandleLoading />
        {!loading && !error && (
          <Box className={classes.container} m={2} p={2}>
            <Box pb={4}>
              <ChangeAvatar />
            </Box>
            <ProfileForm />
          </Box>
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = ({ user }) => {
  const { id: userId } = user
  return { userId }
}

const mapDispatchToProps = (dispatch) => ({
  getUserProfile: (userId) => dispatch(fetchUserProfile(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)

import { useState } from "react"
import {
  Dialog,
  Card,
  DialogActions,
  Button,
  useMediaQuery,
  Link,
} from "@mui/material"
import HelpCenterIcon from "@mui/icons-material/HelpCenter"
import { makeStyles } from "@mui/styles"
import { useTheme } from "@emotion/react"
import config from "config"
import { logClientVideoOpenedEvent } from "api/logClientVideoOpenedEvent"
import LeaveMeetingConfirmationDialog from "./LeaveMeetingConfirmationDialog"
import "@whereby.com/browser-sdk"

const useStyles = makeStyles((theme) => ({
  videoMeetingModal: {
    "& .MuiDialog-paperWidthLg.MuiDialog-paperFullWidth": {
      height: "100%",
    },
    "& .MuiCard-root": {
      height: "95%",
    },
    "& ~ .MuiModal-root .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.65)",
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  meetingRoomDialogFooter: {
    justifyContent: "space-between",
    "& a": {
      display: "flex",
      fontWeight: 500,
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
  },
}))

const VideoMeetingModal = ({
  videoMeetingId,
  openModal,
  videoMeetingUrl,
  onLeaveVideoMeetingConfirmation,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isWidthSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const { videoTroubleshootingUrl } = config
  const [
    openLeaveMeetingConfirmationDialog,
    setOpenLeaveMeetingConfirmationDialog,
  ] = useState(false)

  return (
    <>
      <Dialog
        fullWidth={!isWidthSmDown}
        maxWidth="lg"
        fullScreen={isWidthSmDown}
        open={openModal}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setOpenLeaveMeetingConfirmationDialog(true)
          }
        }}
        className={classes.videoMeetingModal}
      >
        <Card>
          <whereby-embed
            title="meetingRoomVideo"
            room={`${videoMeetingUrl}&lang=sv&langOverride`}
            onLoad={() => logClientVideoOpenedEvent(videoMeetingId)}
            allow="camera;microphone"
            width="100%"
            height="100%"
            style={{ height: "100%" }}
          />
        </Card>
        <DialogActions className={classes.meetingRoomDialogFooter}>
          <Link
            href={videoTroubleshootingUrl}
            underline="always"
            target="_blank"
            rel="noreferrer"
          >
            <HelpCenterIcon />
            Har du videoproblem?
          </Link>
          <Button
            size="small"
            color="default"
            onClick={() => setOpenLeaveMeetingConfirmationDialog(true)}
            className={classes.closeButton}
          >
            Stäng
          </Button>
        </DialogActions>
      </Dialog>
      <LeaveMeetingConfirmationDialog
        openDialog={openLeaveMeetingConfirmationDialog}
        onClose={() => {
          setOpenLeaveMeetingConfirmationDialog(false)
        }}
        onConfirm={() => {
          setOpenLeaveMeetingConfirmationDialog(false)
          onLeaveVideoMeetingConfirmation()
        }}
      />
    </>
  )
}

export default VideoMeetingModal

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { HighlightOff as CloseIcon } from "@mui/icons-material/"

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: "uppercase",
    textAlign: "center",
    color: theme.palette.warning.main,
    fontWeight: 600,
    marginTop: theme.spacing(1.25),
  },
  closeIcon: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
    "&:hover": { backgroundColor: "unset" },
    "& svg": {
      width: "1.45em",
      height: "1.45em",
    },
  },
  subTitle: {
    fontSize: "0.8rem",
    marginBottom: theme.spacing(2),
  },
  content: {
    paddingBottom: 0,
  },
  infoSteps: {
    marginLeft: theme.spacing(2),
    "& > *": {
      fontSize: "0.95rem",
    },
    "& > p": {
      lineHeight: 1.1,
      marginBottom: theme.spacing(2),
    },
  },
  buttonBox: {
    justifyContent: "center",
    padding: theme.spacing(1, 1, 3),
  },
  button: {
    borderRadius: "1.25rem",
    background: [theme.palette.primary.main],
    width: "60%",
    "&:hover": {
      background: [theme.palette.primary.dark],
    },
    "& p": {
      textTransform: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
}))

const VideoInfoModal = ({ openModal, onClose, onVideoMeetingOpen }) => {
  const classes = useStyles()
  return (
    <Dialog open={openModal} onClose={onClose} maxWidth="xs">
      <DialogTitle>
        <Typography variant="h6" component="div" className={classes.title}>
          Viktig info
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography
          variant="subtitle2"
          component="p"
          className={classes.subTitle}
        >
          I nästa steg behöver du ge åtkomst till kamera och mikrofon. Följ
          instruktionerna nedan{" "}
          <Box component="span" sx={{ fontStyle: "italic" }}>
            efter
          </Box>{" "}
          du öppnat videosamtalet.
        </Typography>
        <Box className={classes.infoSteps}>
          <Typography variant="subtitle2" component="span">
            1. Tryck på knappen
          </Typography>
          <Typography variant="subtitle2" component="p" color="primary">
            "Begär åtkomst"
          </Typography>
          <Typography variant="subtitle2" component="span">
            2. Tryck på knappen
          </Typography>
          <Typography variant="subtitle2" component="p" color="primary">
            "Tillåt"
          </Typography>
          <Typography variant="subtitle2" component="span">
            3. Tryck på knappen
          </Typography>
          <Typography variant="subtitle2" component="p" color="primary">
            "Gå med nu"
          </Typography>
          <Typography variant="subtitle2" component="p">
            4. Invänta din psykolog
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.buttonBox}>
        <Button
          onClick={() => onVideoMeetingOpen()}
          className={classes.button}
          variant="contained"
        >
          <Typography>Till videosamtal</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default VideoInfoModal

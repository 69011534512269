/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import { Box, Typography, Link, Hidden } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material/"
import {
  fetchCaregiverAvailabilities as getCaregiverAvailabilities,
  setCaregiver as putCaregiver,
  removeCaregiver as unSelectCaregiver,
} from "redux/caregiver"
import {
  fetchUserChatSession as getUserChatSession,
  fetchUserChat as getUserChat,
} from "redux/chat"
import { getUserProfile as fetchUserProfile } from "redux/user"
import { showAlert as displayAlert } from "redux/common"
import { unmatchCaregiver } from "api/unmatchCaregiver"
import VideoPaymentModal from "components/VideoPaymentModal"
import FrikortModal from "components/FrikortModal"
import getUserAge from "utils/getUserAge"
import isFreeCardValid from "utils/isFreeCardValid"
import { sessionType } from "utils/sessionType"
import isActiveSession from "utils/isActiveSession"
import config from "config"
import LoadingScreen from "components/LoadingScreen"
import { navigationValues } from "utils/navigationValues"
import AvailableVideoTimes from "./components/AvailableVideoTimes"
import UnmatchCaregiverConfirmationDialog from "../../components/UnmatchCaregiverConfirmationDialog"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      overflow: "unset",
      height: "100%",
      paddingBottom: theme.spacing(7.5),
      position: "relative",
    },
  },
  content: {
    padding: theme.spacing(7),
    boxSizing: "border-box",
    flex: 4,
    textAlign: "center",
    height: "calc(100vh - 64px)",
    overflowY: "auto",
    [theme.breakpoints.up("lg")]: {
      borderRight: `0.5px solid ${theme.palette.gray}`,
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
      flex: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 3, 14, 3),
      overflowY: "unset",
      height: "100%",
    },
  },
  hidden: {
    display: "none",
  },
  subtitle: {
    fontWeight: 400,
    fontSize: "12px",
    margin: "8px auto",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  subtitleBackground: {
    fontWeight: 400,
    fontSize: "12px",
    margin: "20px auto",
    padding: theme.spacing(1, 2),
    backgroundColor: "#C8E2E4",
    borderRadius: theme.spacing(1),
    width: "50%",
    color: theme.palette.black,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  link: {
    textAlign: "center",
    cursor: "pointer",
    "& .MuiIconButton-root": {
      "&:hover": {
        backgroundColor: "unset",
      },
      "& p": {
        color: theme.palette.dark_gray,
        textDecorationColor: theme.palette.dark_gray,
        textDecoration: "underline",
        fontWeight: 500,
      },
    },
  },
  centered: {
    display: "flex",
    flexDirection: "column",
    height: "60%",
    justifyContent: "center",
    alignItems: "center",
  },
  unmatchBox: {
    textAlign: "center",
  },
  linkButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    "& p": {
      fontWeight: 500,
      fontSize: "0.85rem",
    },
  },
  videoInfoContainer: {
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  videoInfoTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: "1.4rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  videoInfoSubtitle: {
    fontSize: "1rem",
    margin: theme.spacing(1, 0, 2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  videoInfoSteps: {
    "& > p": {
      fontSize: "1.05rem",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.85rem",
        marginBottom: theme.spacing(1.25),
      },
      "& > span": {
        fontWeight: 700,
        fontSize: "1.05rem",
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.85rem",
        },
      },
    },
  },
  upperSection: {
    marginTop: theme.spacing(1),
    "& p": {
      fontSize: "1.1rem",
    },
  },
  backIcon: {
    position: "fixed",
    left: theme.spacing(2),
    top: theme.spacing(1.9),
    zIndex: 1202,
    "&:hover": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down(330)]: {
      left: theme.spacing(1),
    },
  },
  calmAreaText: {
    fontSize: "1.05rem",
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85rem",
    },
  },
}))

const Video = ({
  availabilities,
  fetchCaregiverAvailabilities,
  loading,
  user,
  lastChatSession,
  fetchUserChatSession,
  fetchUserChat,
  caregiverData,
  getUserProfile,
  setCaregiver,
  showAlert,
}) => {
  sessionStorage.setItem("navigateToVideoPage", "false")
  const classes = useStyles()
  const {
    id: userId,
    email,
    phoneNumber,
    meeting: videoMeeting,
    socialSecurity,
    freeCard,
    previousVideoMeeting,
    onboardingFinished: hasUserFinishedOnboarding,
  } = user
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const showCalendarQuery = !!queryParams.get("showCalendar")
  const {
    firstName: caregiverFirstName,
    lastName: caregiverLastName,
    id: caregiverId,
  } = caregiverData
  const [isVideoPaymentModalOpen, setVideoPaymentModalOpen] = useState(false)
  const [isFrikortOpen, setFrikortOpen] = useState(false)
  const [isFreeAgeUser, setIsFreeAgeUser] = useState(false)
  const [isFreeCardUser, setIsFreeCardUser] = useState(false)
  const [selectedVideoCallSlot, setSelectedVideoCallSlot] = useState({
    start: null,
    end: null,
  })
  const [
    openUnmatchCaregiverConfirmationDialog,
    setOpenUnmatchCaregiverConfirmationDialog,
  ] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [
    unmatchCaregiverConfirmationDialogError,
    setUnmatchCaregiverConfirmationDialogError,
  ] = useState(null)
  const [hasActiveSession, setHasActiveSession] = useState(false)
  const { timeRequestFormUrl } = config
  const uriEncodedCaregiverFullName = encodeURIComponent(
    `${caregiverFirstName} ${caregiverLastName}`
  )
  // eslint-disable-next-line max-len
  const prefilledTimeRequestFormUrl = `${timeRequestFormUrl}#caregiver_name=${uriEncodedCaregiverFullName}&patient_id=${userId.slice(
    0,
    6
  )}`

  useEffect(() => {
    if (userId) {
      const loadUserData = async () => {
        await fetchUserChat(userId)
        await fetchUserChatSession({ userId, limit: 1 })
      }
      const loadAvailabilities = async () => {
        await fetchCaregiverAvailabilities({
          caregiverId,
          previousVideoMeetingStart: previousVideoMeeting?.start,
          previousMeetingMissed:
            previousVideoMeeting?.costReceiver === "ignore",
        })
      }
      const checkIfFreeAgeOrFreeCardUser = () => {
        const userAge = getUserAge(socialSecurity)
        const freeAgeUser = userAge < 20 || userAge >= 85
        const freeCardUser = isFreeCardValid(freeCard?.expirationDate)
        setIsFreeAgeUser(freeAgeUser)
        setIsFreeCardUser(freeCardUser)
      }

      if (lastChatSession) {
        setHasActiveSession(isActiveSession(lastChatSession))
      }
      if (hasUserFinishedOnboarding) {
        loadUserData()
      }
      if (caregiverId) {
        loadAvailabilities()
        checkIfFreeAgeOrFreeCardUser()
      }
    }

    return unSelectCaregiver()
  }, [
    userId,
    caregiverId,
    videoMeeting?.start,
    lastChatSession?.id,
    hasUserFinishedOnboarding,
  ])

  useEffect(
    () => () => {
      window.scrollTo({ top: 0 })
    },
    []
  )

  const handleUnmatchFromCaregiver = async () => {
    try {
      await unmatchCaregiver(userId)
      window.location.replace("/")
    } catch (error) {
      setUnmatchCaregiverConfirmationDialogError(
        "Något gick fel, vänligen försök igen inom kort. Om problemen består, kontakta info@dinpsykolog.se."
      )
    }
  }

  const handleOpenUnmatchCaregiverConfirmationDialog = () => {
    setUnmatchCaregiverConfirmationDialogError(null)
    setOpenUnmatchCaregiverConfirmationDialog(true)
  }

  const handleHideAvailableCalendar = () => {
    navigate("/video")
    setShowCalendar(false)
  }

  useEffect(() => {
    const popStateAction = () => {
      if (isFrikortOpen) {
        setVideoPaymentModalOpen(true)
        setFrikortOpen(false)
      } else if (isVideoPaymentModalOpen) {
        setVideoPaymentModalOpen(false)
      } else if (!hasUserFinishedOnboarding && !videoMeeting?.start) {
        setCaregiver({
          loading: false,
          data: {},
          error: false,
        })
      } else navigate(-1)
    }
    window.addEventListener("popstate", popStateAction)
    setShowCalendar(!!showCalendarQuery)
    const navigateUrl = showCalendarQuery
      ? "/video?showCalendar=true"
      : "/video"
    navigate(!hasUserFinishedOnboarding ? "/" : navigateUrl)
    return () => window.removeEventListener("popstate", popStateAction)
  }, [
    isFrikortOpen,
    isVideoPaymentModalOpen,
    hasUserFinishedOnboarding,
    showCalendarQuery,
  ])

  const renderNoAvailableTimes = () => (
    <Box className={classes.centered}>
      <Typography textAlign="center" fontWeight="bold">
        Inga tillgängliga tider
      </Typography>
      <Link
        className={classes.linkButton}
        href={prefilledTimeRequestFormUrl}
        underline="always"
        target="_blank"
        rel="noreferrer"
      >
        <Typography>Önska tid</Typography>
      </Link>
      <Link
        className={classes.linkButton}
        component="button"
        underline="always"
        onClick={() =>
          !hasUserFinishedOnboarding
            ? setCaregiver({
                loading: false,
                data: {},
                error: false,
              })
            : handleOpenUnmatchCaregiverConfirmationDialog()
        }
      >
        <Typography>
          {!hasUserFinishedOnboarding ? "Tillbaka" : "Byt psykolog"}
        </Typography>
      </Link>
    </Box>
  )

  const renderLoading = () => <LoadingScreen screen={navigationValues.VIDEO} />

  const renderVideoInfoText = () => (
    <Box className={!showCalendar ? classes.content : classes.hidden}>
      <Box className={classes.videoInfoContainer}>
        <Typography variant="subtitle1" className={classes.videoInfoTitle}>
          Att tänka på inför ditt videosamtal
        </Typography>
        <Typography variant="subtitle2" className={classes.videoInfoSubtitle}>
          I samband med att du startar ditt videosamtal behöver du ge tillgång
          till kamera och mikrofon.
        </Typography>
        <Box className={classes.videoInfoSteps}>
          <Typography variant="subtitle2" component="p">
            1. Tryck på knappen{" "}
            <Typography variant="subtitle2" component="span" color="primary">
              "Begär åtkomst"
            </Typography>
          </Typography>
          <Typography variant="subtitle2" component="p">
            2. Tryck på knappen{" "}
            <Typography variant="subtitle2" component="span" color="primary">
              "Tillåt"
            </Typography>
          </Typography>
          <Typography variant="subtitle2" component="p">
            3. Tryck på knappen{" "}
            <Typography variant="subtitle2" component="span" color="primary">
              "Gå med nu"
            </Typography>
          </Typography>
          <Typography variant="subtitle2" component="p">
            4. Invänta din psykolog
          </Typography>
        </Box>
        <Typography variant="subtitle2" className={classes.calmAreaText}>
          Tänk på att sitta på en ostörd plats som du känner dig trygg i under
          videosamtalet.
        </Typography>
      </Box>
    </Box>
  )

  const openVideoPaymentModal = (start, end, timeZone) => {
    setSelectedVideoCallSlot({ start, end, timeZone })
    setVideoPaymentModalOpen(true)
  }

  const renderContent = () => {
    if (videoMeeting?.start && !showCalendar) {
      return renderVideoInfoText()
    }
    if (loading || !availabilities) {
      return renderLoading()
    }
    return (
      <Box className={classes.content}>
        {videoMeeting?.start && renderVideoInfoText()}
        <Typography
          variant="h5"
          color="primary"
          sx={{ fontWeight: 600, fontSize: "20px" }}
        >
          {videoMeeting?.start ? "Tillgängliga tider" : "Boka videosamtal"}
        </Typography>
        {videoMeeting?.start && (
          <Typography
            variant="body1"
            color="primary"
            className={
              showCalendar ? classes.subtitleBackground : classes.subtitle
            }
          >
            För att boka en av dessa tider, avboka först din nuvarande bokning.
          </Typography>
        )}
        {availabilities.length ? (
          <>
            <AvailableVideoTimes
              userId={userId}
              userEmail={email}
              userPhone={phoneNumber}
              caregiverFirstName={caregiverFirstName}
              caregiverLastName={caregiverLastName}
              caregiverId={caregiverId}
              availabilities={availabilities}
              isFreeAgeUser={isFreeAgeUser}
              isFreeCardUser={isFreeCardUser}
              freeCard={freeCard}
              openPaymentModal={(videoCallStart, videoCallEnd, timeZone) =>
                openVideoPaymentModal(videoCallStart, videoCallEnd, timeZone)
              }
              getUserProfile={getUserProfile}
              showAlert={({ type, message }) => showAlert({ type, message })}
              bookingDisabled={!!showCalendar}
            />
            <Box className={classes.unmatchBox}>
              <Link
                className={classes.linkButton}
                component="button"
                underline="always"
                onClick={() =>
                  !hasUserFinishedOnboarding
                    ? setCaregiver({
                        loading: false,
                        data: {},
                        error: false,
                      })
                    : handleOpenUnmatchCaregiverConfirmationDialog()
                }
              >
                {!videoMeeting?.start && (
                  <Typography>
                    {!hasUserFinishedOnboarding ? "Tillbaka" : "Byt psykolog"}
                  </Typography>
                )}
              </Link>
            </Box>
          </>
        ) : (
          renderNoAvailableTimes()
        )}
        {!!showCalendar && (
          <Link
            className={classes.linkButton}
            component="button"
            underline="always"
            onClick={handleHideAvailableCalendar}
          >
            <Typography>Stäng kalender</Typography>
          </Link>
        )}
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      {!hasUserFinishedOnboarding && !videoMeeting?.start && (
        <Hidden smUp>
          <ArrowBackIcon
            fontSize="large"
            color="primary"
            className={classes.backIcon}
            onClick={() =>
              setCaregiver({
                loading: false,
                data: {},
                error: false,
              })
            }
          />
        </Hidden>
      )}
      {renderContent()}
      <VideoPaymentModal
        open={isVideoPaymentModalOpen}
        close={() => {
          setVideoPaymentModalOpen(false)
        }}
        onPaymentDecision={() => {
          setVideoPaymentModalOpen(false)
          setFrikortOpen(true)
        }}
        selectedVideoCallSlot={selectedVideoCallSlot}
        caregiverId={caregiverId}
        userId={userId}
        getUserProfile={getUserProfile}
        showAlert={({ type, message }) => showAlert({ type, message })}
        freeCardAmountToLimit={freeCard?.amountToLimit}
      />
      <FrikortModal
        open={isFrikortOpen}
        close={() => setFrikortOpen(false)}
        goBack={() =>
          isFrikortOpen
            ? (setFrikortOpen(false), setVideoPaymentModalOpen(true))
            : null
        }
        sessionType={sessionType.VIDEO}
        selectedVideoCallSlot={selectedVideoCallSlot}
      />
      <UnmatchCaregiverConfirmationDialog
        openDialog={openUnmatchCaregiverConfirmationDialog}
        onClose={() => setOpenUnmatchCaregiverConfirmationDialog(false)}
        onUnmatch={handleUnmatchFromCaregiver}
        error={unmatchCaregiverConfirmationDialogError}
        hasActiveSession={hasActiveSession}
      />
    </Box>
  )
}

const mapStateToProps = (state) => {
  const { caregiver, user, chat } = state

  const { availabilities, data: caregiverData, loading } = caregiver
  const { session: lastChatSession } = chat

  return {
    availabilities,
    caregiverData,
    loading,
    user,
    lastChatSession,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCaregiverAvailabilities: ({
    caregiverId,
    previousVideoMeetingStart,
    previousMeetingMissed,
  }) =>
    dispatch(
      getCaregiverAvailabilities({
        caregiverId,
        previousVideoMeetingStart,
        previousMeetingMissed,
      })
    ),
  fetchUserChatSession: (data) => dispatch(getUserChatSession(data)),
  fetchUserChat: (userId) => dispatch(getUserChat(userId)),
  getUserProfile: (userId) => dispatch(fetchUserProfile(userId)),
  showAlert: (data) => dispatch(displayAlert(data)),
  setCaregiver: (caregiver) => dispatch(putCaregiver(caregiver)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Video)

import React from "react"
import { Box, IconButton } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Link } from "react-router-dom"

const useStyles = makeStyles(() => ({
  container: {
    textDecoration: "none",
  },
}))

export const TouchClick = ({ children, onClick = () => {}, ...rest }) => {
  const classes = useStyles()

  return (
    <Box onClick={onClick} className={classes.container} {...rest}>
      <IconButton>{children}</IconButton>
    </Box>
  )
}

const TouchButton = ({ children, to = "", ...rest }) => {
  const classes = useStyles()

  return (
    <Box {...rest} component={Link} to={to} className={classes.container}>
      <IconButton>{children}</IconButton>
    </Box>
  )
}

export default TouchButton

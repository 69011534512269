import Api from "api"

export const getRecommendedCaregivers = (
  userId,
  sortBy = "recommended",
  filterDays = [],
  filterHours = []
) =>
  Api.get(`
users/caregiver/recommended/${userId}?sortBy=${sortBy}&filterDays=${filterDays}&filterHours=${filterHours}
`)

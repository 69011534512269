import React from "react"
import { useController } from "react-hook-form"
import MuiDatePicker from "@mui/lab/DatePicker"
import { TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/lab"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import sv from "date-fns/locale/sv"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(() => ({
  labelText: {
    "& label": {
      color: "#A8A8A8",
      fontWeight: "300",
    },
  },
}))

const DatePicker = ({ name, controller, label }) => {
  const { field, fieldState } = useController({
    name,
    control: controller,
  })
  const { onChange, ...rest } = field || {}
  const classes = useStyles()
  const error = fieldState?.error
  const helperText = error?.message
  const invalid = !!error?.message
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={sv}>
      <MuiDatePicker
        {...rest}
        inputVariant="outlined"
        mask="____-__-__"
        inputFormat="yyyy-MM-dd"
        inputProps={{
          placeholder: "ÅÅÅÅ-MM-DD",
        }}
        cancelText="Avbryt"
        label="Utgångsdatum"
        onChange={(newValue) => onChange(newValue)}
        minDate={new Date()}
        renderInput={(params) => (
          <TextField
            className={classes.labelText}
            helperText={helperText}
            label={label}
            {...params}
            error={invalid}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default DatePicker

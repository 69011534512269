import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { getFormattedDateAndTime } from "utils/getFormattedDateAndTime"

const useStyles = makeStyles((theme) => ({
  greenButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    opacity: "0.8",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      opacity: "1",
    },
  },
  grayButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
  },
}))

const MeetingBookedByCaregiverModal = ({
  onClose,
  openDialog,
  onReservedTimeRejection,
  openPaymentDialog,
  meeting,
}) => {
  const classes = useStyles()
  const { start, paid, paymentLink } = meeting || {}
  return (
    <Dialog
      open={openDialog}
      onClose={!paid && paymentLink ? onReservedTimeRejection : onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Videosamtal reserverat</DialogTitle>
      {start && (
        <DialogContent>
          <Typography>
            Din psykolog har reserverat en tid åt dig{" "}
            <Typography textAlign="center" fontWeight={600} component="span">
              {`${getFormattedDateAndTime(start).toLowerCase()}.`}
            </Typography>
          </Typography>
          {!paid && (
            <Typography
              variant="body2"
              sx={{ fontStyle: "italic", lineHeight: 2 }}
            >
              OBS! Tiden är reserverad i 25 min. Vänligen betala för att behålla
              den.
            </Typography>
          )}
        </DialogContent>
      )}
      <DialogActions>
        {!paid && paymentLink ? (
          <>
            <Button
              onClick={onReservedTimeRejection}
              className={classes.grayButton}
              color="default"
            >
              Nej tack
            </Button>
            <Button
              onClick={() => openPaymentDialog()}
              className={classes.greenButton}
            >
              Betala nu
            </Button>
          </>
        ) : (
          <Button onClick={onClose} className={classes.greenButton}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default MeetingBookedByCaregiverModal

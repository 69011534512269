import React from "react"
import { connect } from "react-redux"
import { Snackbar, Alert as MuiAlert } from "@mui/material"
import { selectCommonState } from "redux/common/selectors"
import { hideAlert } from "redux/common"

const Alert = ({ alert, hide }) => {
  if (alert) {
    const { type, message, show, duration } = alert

    return (
      <Snackbar
        open={show}
        onClose={(_, reason) => {
          if (reason !== "clickaway") {
            hide({ type })
          }
        }}
        autoHideDuration={duration}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={type}
          color={type}
          onClose={(_, reason) => {
            if (reason !== "clickaway") {
              hide({ type })
            }
          }}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    )
  }
  return null
}

function mapStateToProps(state) {
  const { alert } = selectCommonState(state)

  return { alert }
}

const mapDispatchToProps = (dispatch) => ({
  hide: ({ type }) => dispatch(hideAlert({ type })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Alert)

import { createSlice } from "@reduxjs/toolkit"

const getAlertType = (type) => {
  switch (type) {
    case "error":
    case "warning":
    case "info":
      return type
    default:
      return "success"
  }
}

const initialState = {
  loading: true,
  alert: null,
  token: null,
  chatStarted: false,
  sessionCount: 0,
  socket: null,
}

const sliceName = "common"

const commonSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setToken: (state, action) => {
      Object.assign(state, { token: action.payload })
    },
    setSocket: (state, action) => {
      Object.assign(state, { socket: action.payload })
    },
    setInitialLoading: (state, action) => {
      Object.assign(state, { loading: action.payload })
    },
    setChatStarted: (state, action) => {
      Object.assign(state, { chatStarted: action.payload })
    },
    setSessionCount: (state, action) => {
      Object.assign(state, { sessionCount: action.payload })
    },
    setTreatmentStageImage: (state, action) => {
      Object.assign(state, { treatmentStageImage: action.payload })
    },
    showAlert: (state, action) => {
      const defaultDuration = 30000
      Object.assign(state, {
        alert: {
          type: getAlertType(action.payload.type),
          message: action.payload.message,
          duration: action.payload.duration || defaultDuration,
          show: true,
        },
      })
    },
    hideAlert: (state, action) => {
      Object.assign(state, {
        alert: { show: false, type: getAlertType(action.payload.type) },
      })
    },
  },
})

export default commonSlice

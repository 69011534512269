import React from "react"
import { Divider, Typography, Box } from "@mui/material"

const DividerText = ({ text = "", ...rest }) => (
  <Box color="gray" {...rest}>
    <Typography>{text.toUpperCase()}</Typography>
    <Divider />
  </Box>
)

export default DividerText

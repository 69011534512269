import Cookie from "js-cookie"
import { setInitialLoading, setToken } from "redux/common"
import { getUserAndSocketConnection } from "redux/user"
import Api from "api"

export const getUserFromCookieOrSessionStorage = async (dispatch) => {
  let patientObject
  const patient = Cookie.get("patient")
  const { id, token } = patient ? JSON.parse(patient) : {}
  Api.defaults.headers.common.Authorization = `Bearer ${token}`

  if (id) {
    await dispatch(getUserAndSocketConnection(id)).then(() => {
      dispatch(setToken(token))
      sessionStorage.setItem("patient", JSON.stringify({ id, token }))
      patientObject = JSON.parse(patient)
    })
  } else {
    const sessionStoragePatient = sessionStorage.getItem("patient")
    if (sessionStoragePatient) {
      const { id: sessionStorageUserId, token: sessionStorageUserToken } =
        JSON.parse(sessionStoragePatient)
      Api.defaults.headers.common.Authorization = `Bearer ${sessionStorageUserToken}`
      await dispatch(getUserAndSocketConnection(sessionStorageUserId)).then(
        () => {
          dispatch(setToken(sessionStorageUserToken))
          patientObject = JSON.parse(sessionStoragePatient)
        }
      )
    }
  }
  dispatch(setInitialLoading(false))
  return patientObject
}

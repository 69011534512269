export const getOsAndBrowser = (userAgent) => {
  if (!userAgent) {
    return {
      os: "Unknown OS",
      browser: "Unknown Browser",
    }
  }

  // Regular expressions to match OS and browser with version
  const osRegex = /\b(Android|iPhone OS|Mac OS X|Windows NT) (\d+[._]\d+|\d+)/i
  const browserRegex =
    /\b(Safari|Chrome|Firefox|Edge|Opera|MSIE|Trident)\/(\d+[._]\d+|\d+)/i

  // Extract OS and browser from userAgent string
  const osMatch = userAgent.match(osRegex)
  const browserMatch = userAgent.match(browserRegex)

  // Get the matched OS and browser
  const os = osMatch ? `${osMatch[1]} ${osMatch[2]}` : "Unknown OS"
  const browser = browserMatch
    ? `${browserMatch[1]} ${browserMatch[2]}`
    : "Unknown Browser"

  return {
    os,
    browser,
  }
}

import { useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormGroup,
  IconButton,
  Tooltip,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import Input from "components/Input"
import multipleFreeTextValidationSchema from "validation/multipleFreeTextValidation"
import { useForm } from "react-hook-form"
import { updateUserProfile } from "redux/user"
import { useDispatch } from "react-redux"
import { Close as CloseIcon, Info as InfoIcon } from "@mui/icons-material"
import { getFormattedDateAndTime } from "utils/getFormattedDateAndTime"
import { getFirstLetter } from "utils/getFirstLetter"
import { trackEvent } from "utils/trackEvent"
import { updateUser } from "api/updateUser"

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: theme.spacing(1.25),
      margin: theme.spacing(2),
      minWidth: "350px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "90%",
      },
    },
  },
  dialogTitle: {
    fontWeight: 600,
    lineHeight: 1.3,
    position: "relative",
    padding: theme.spacing(2, 3, 0),
    "& .MuiTypography-caption": {
      margin: theme.spacing(1.5, 0),
      fontWeight: 500,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  formGroup: {
    padding: theme.spacing(1, 0, 2),
    "& .MuiTextField-root:first-of-type": {
      marginBottom: theme.spacing(2),
    },
  },
  actions: {
    padding: theme.spacing(1, 3),
  },
  bookButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    opacity: "0.8",
    width: "100%",
    textTransform: "none",
    borderRadius: "100px",
    margin: `0 auto ${theme.spacing(2)}`,
    padding: theme.spacing(1.25, 12),
    fontWeight: 500,
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      opacity: "1",
    },
    "&.fullWidth": {
      width: "100%",
    },
  },
  invalidFreeCardText: {
    color: theme.palette.warning.main,
    fontSize: "0.7rem",
    textAlign: "justify",
    lineHeight: 1.4,
  },
  validFreeCardText: {
    color: theme.palette.primary.main,
    fontSize: "0.7rem",
    textAlign: "center",
    lineHeight: 1.4,
    margin: theme.spacing(0, 3, 3),
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F3F9FA",
    fontWeight: 600,
    "& svg": {
      marginLeft: theme.spacing(0.5),
    },
  },
  caregiverName: {
    fontSize: "0.85rem",
  },
  selectedTimeSlot: {
    fontSize: "0.9rem",
    lineHeight: 1,
    "&.MuiTypography-caption": {
      fontWeight: 700,
    },
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
  },
}))

const resolver = (data) => {
  const { error, value } = multipleFreeTextValidationSchema(data)
  const responseValues = error ? {} : value
  const responseErrors = error
    ? error.details.reduce(
        (previous, currentError) => ({
          ...previous,
          [currentError.path[0]]: currentError,
        }),
        {}
      )
    : {}

  return {
    values: responseValues,
    errors: responseErrors,
  }
}

const BookingConfirmationModal = ({
  onClose,
  openDialog,
  onContinueWithBooking,
  userId,
  userEmail,
  userPhone,
  selectedTimeSlot,
  caregiverFirstName,
  caregiverLastName,
  isFreeAgeUser,
  isFreeCardInvalidForSelectedTimeSlot,
  freeCard,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const shouldDisplayEmailAndPhoneInputs = !userEmail || !userPhone
  const formattedSelectedTimeSlot = selectedTimeSlot
    ? getFormattedDateAndTime(selectedTimeSlot)
    : null
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver,
    defaultValues: {
      email: userEmail || "",
      mobileNumber: userPhone || "",
    },
  })

  useEffect(() => {
    reset({
      email: userEmail || "",
      mobileNumber: userPhone || "",
    })
  }, [userEmail, userPhone, reset])

  const onSubmit = async (formData) => {
    const data = { email: formData.email, phoneNumber: formData.mobileNumber }
    await updateUser(userId, data)
    dispatch(
      updateUserProfile({
        data,
        shouldShowAlert: false,
      })
    )
    onContinueWithBooking()
    reset()
  }
  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        onClose()
        reset()
      }}
      className={classes.dialog}
      maxWidth="xs"
      disableRestoreFocus
      keepMounted
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle className={classes.dialogTitle}>
          <IconButton
            onClick={() => {
              onClose()
              reset()
            }}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="inherit"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            Boka tiden
          </Typography>
          <Typography
            variant="caption"
            component="p"
            className={classes.selectedTimeSlot}
          >
            {formattedSelectedTimeSlot}
          </Typography>
          <Typography
            variant="caption"
            component="p"
            className={classes.caregiverName}
          >
            {caregiverFirstName} {getFirstLetter(caregiverLastName)}
          </Typography>
          {shouldDisplayEmailAndPhoneInputs && (
            <Typography variant="caption" component="p">
              Bekräftelse skickas via e-post och påminnelse med länk via sms.
            </Typography>
          )}
          {isFreeCardInvalidForSelectedTimeSlot && (
            <Typography
              variant="caption"
              component="p"
              className={classes.invalidFreeCardText}
            >
              Frikortet är inte giltigt för det valda videosamtalet. Det är dock
              möjligt att betala med kort i nästa steg.
            </Typography>
          )}
        </DialogTitle>
        {shouldDisplayEmailAndPhoneInputs && (
          <DialogContent sx={{ paddingBottom: 0 }}>
            <FormGroup className={classes.formGroup}>
              <Input
                key="email"
                name="email"
                label="E-postadress"
                defaultValue={userEmail || ""}
                control={control}
                errors={errors}
              />
              <Input
                key="mobileNumber"
                name="mobileNumber"
                label="Mobilnummer"
                defaultValue={userPhone || ""}
                control={control}
                errors={errors}
              />
            </FormGroup>
          </DialogContent>
        )}
        <DialogActions className={classes.actions}>
          <Button
            className={`${classes.bookButton} ${
              shouldDisplayEmailAndPhoneInputs ? "fullWidth" : ""
            }`}
            type={shouldDisplayEmailAndPhoneInputs ? "submit" : "button"}
            onClick={() => {
              trackEvent("bookingCompleted")
              return shouldDisplayEmailAndPhoneInputs
                ? null
                : (onContinueWithBooking(), reset())
            }}
          >
            Boka
          </Button>
        </DialogActions>
        {(freeCard.expirationDate && !isFreeCardInvalidForSelectedTimeSlot) ||
        isFreeAgeUser ? (
          <Typography
            variant="caption"
            component="p"
            className={classes.validFreeCardText}
          >
            {!isFreeAgeUser && <>Du har frikort - </>}besöket är avgiftsfritt.
            {isFreeAgeUser && (
              <Tooltip
                title="För ungdomar över 18 år (fram till 1:a januari det år man fyller 20)
                och för äldre över 85 år är alla kontakter kostnadsfria."
                enterTouchDelay={0}
              >
                <InfoIcon fontSize="small" />
              </Tooltip>
            )}
          </Typography>
        ) : (
          ""
        )}
      </form>
    </Dialog>
  )
}

export default BookingConfirmationModal

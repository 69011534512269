import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Box, Typography, Button, Hidden } from "@mui/material"
import { makeStyles } from "@mui/styles"
import ChatBar from "pages/chat/components/ChatBar"
import ChatContainer from "pages/chat/components/ChatContainer"
import { fetchUserChat as getUserChat } from "redux/chat"
import { resetUnreadMessagesNumber as setUnreadMessagesNumberToZero } from "redux/user"
import { chatMessagesSeen } from "api/chatMessagesSeen"
import LoadingScreen from "components/LoadingScreen"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { navigationValues } from "utils/navigationValues"
import { selectCommonState } from "redux/common/selectors"
import CancelMeetingBanner from "components/CancelMeetingBanner"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(7.5),
    },
  },
  content: {
    boxSizing: "border-box",
    flex: 4,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      borderRight: `0.5px solid ${theme.palette.gray}`,
    },
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  icon: {
    height: 200,
    width: 200,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: 150,
      width: 150,
    },
  },
  errorButton: {
    marginTop: theme.spacing(2),
  },
}))

const Chat = ({
  fetchUserChat,
  resetUnreadMessagesNumber,
  user,
  chat,
  socket,
}) => {
  const classes = useStyles()
  const { loading, error, id: activeChatId } = chat
  const { id: userId } = user
  useEffect(() => {
    const loadData = async () => {
      if (userId) {
        await fetchUserChat(userId)
      }
    }
    loadData()
  }, [userId])

  useEffect(() => {
    if (activeChatId && socket) {
      chatMessagesSeen(activeChatId, userId)
      socket.emit("messageSeen", { chatId: activeChatId, userId })
      resetUnreadMessagesNumber()
    }
  }, [activeChatId, socket?.connected])

  if (error) {
    return (
      <Box className={classes.loading}>
        <ErrorOutlineIcon color="primary" className={classes.icon} />
        <Typography variant="h4">Något gick fel!</Typography>
        <Button
          variant="contained"
          onClick={() => fetchUserChat(userId)}
          className={classes.errorButton}
        >
          Försök igen
        </Button>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      {loading ? (
        <LoadingScreen screen={navigationValues.MESSAGES} />
      ) : (
        <Box className={classes.content}>
          <Hidden smUp>
            <CancelMeetingBanner />
          </Hidden>
          <ChatContainer />
          <ChatBar />
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  const { socket } = selectCommonState(state)
  const { user, chat } = state

  return { chat, user, socket }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUserChat: (userId) => dispatch(getUserChat(userId)),
  resetUnreadMessagesNumber: () => dispatch(setUnreadMessagesNumberToZero()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Chat)

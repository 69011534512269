import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Modal, Box, Typography, Button, FormGroup } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useForm } from "react-hook-form"

import {
  showAlert as displayAlert,
  setChatStarted as changeChatToStarted,
} from "redux/common"
import { fetchCaregiver as getCaregiver } from "redux/caregiver"
import freeCardValidationSchema from "validation/freeCardValidation"
import { createFreeCard } from "api/createFreeCard"
import { changePaymentToFreeCard } from "api/changePaymentToFreeCard"
import { createVideoMeetingForFreeAgeOrFreeCardUser } from "api/createVideoMeetingForFreeAgeOrFreeCardUser"
import Input from "components/Input"
import DatePicker from "components/DatePicker"
import { getUserProfile as fetchUserProfile } from "redux/user"
import { sessionType as sessionTypes } from "utils/sessionType"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    backgroundColor: theme.palette.lighter_gray,
    padding: theme.spacing(4),
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "50%",
    },
  },
  buttonContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(4),
  },
  button: {
    display: "flex",
    flex: 1,
    marginRight: theme.spacing(2),
    "&:last-of-type": {
      marginRight: 0,
    },
    padding: theme.spacing(2),
    textTransform: "none",
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      pointerEvents: "none",
    },
  },
  title: {
    color: theme.palette.primary.main,
    textAlign: "center",
    fontWeight: theme.typography.fontWeightMedium,
  },
  questionTitle: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
  questionSubtitle: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.6rem",
    },
  },
  text: {
    color: theme.palette.black,
    marginTop: theme.spacing(2),
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    "& a:hover": {
      cursor: "pointer",
    },
  },
  reasonsList: {
    fontSize: "1rem",
    lineHeight: 1.6,
    listStyleType: "square",
  },
  formContainer: {
    flexGrow: 1,
  },
}))

const resolver = (data) => {
  const { error, value } = freeCardValidationSchema(data)

  const responseValues = error ? {} : value
  const responseErrors = error
    ? error.details.reduce(
        (previous, currentError) => ({
          ...previous,
          [currentError.path[0]]: currentError,
        }),
        {}
      )
    : {}

  return {
    values: responseValues,
    errors: responseErrors,
  }
}

const FrikortModal = ({
  getUserProfile,
  fetchCaregiver,
  showAlert,
  open,
  close,
  userId,
  caregiver,
  goBack,
  sessionType,
  selectedVideoCallSlot,
  setChatStarted,
  videoMeetingId,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { control, handleSubmit, formState, reset } = useForm({
    resolver,
    defaultValues: {
      cardNumber: "",
      expirationDate: null,
    },
  })
  const { isSubmitting } = formState

  useEffect(() => {
    if (!caregiver.data) {
      fetchCaregiver(userId)
    }
    const popStateAction = () => {
      goBack()
      navigate(1)
    }
    window.addEventListener("popstate", popStateAction)
    return () => window.removeEventListener("popstate", popStateAction)
  }, [])

  const onSubmit = (data) => {
    if (sessionType === sessionTypes.CHAT) {
      return new Promise(() => {
        createFreeCard({
          userId,
          ...data,
        })
          .then(async () => {
            setChatStarted(true)
            await getUserProfile(userId)
            close()
          })
          .catch(() => {
            reset({
              cardNumber: data.cardNumber,
              expirationDate: data.expirationDate.toString(),
            })
            showAlert({ type: "error", message: "Något gick fel" })
          })
      })
    }

    return new Promise(() => {
      const request = videoMeetingId
        ? changePaymentToFreeCard({
            meetingId: videoMeetingId,
            ...data,
          })
        : createVideoMeetingForFreeAgeOrFreeCardUser({
            caregiverId: caregiver?.data?.id,
            start: selectedVideoCallSlot?.start,
            slotTimeZone: selectedVideoCallSlot?.timeZone,
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            ...data,
          })
      request
        .then(async ({ data: responseData }) => {
          setChatStarted(true)
          await getUserProfile(userId)
          close()
          navigate(
            `/payment-successful?type=20&ikbtInvite=${responseData?.ikbtInvite}`
          )
        })
        .catch((error) => {
          if (error?.response?.status === 405) {
            window.location.reload()
          } else {
            let errorMessage =
              "Tiden är dessvärre inte längre tillgänglig. Var god och välj en annan tid."

            if (error?.response?.status === 401) {
              errorMessage = "Tiden kan ej bokas så nära inpå mötet."
            } else if ([403, 503].includes(error?.response?.status)) {
              errorMessage = error?.response?.data
                ? error.response.data
                : errorMessage
            }
            reset({
              cardNumber: data.cardNumber,
              expirationDate: data.expirationDate.toString(),
            })
            showAlert({ type: "error", message: errorMessage })
          }
        })
    })
  }

  return (
    <Modal className={classes.root} open={open} onClose={close}>
      <Box className={classes.wrapper}>
        <Typography variant="h4" className={classes.title}>
          Frikort
        </Typography>
        <Box mt={5} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formContainer}>
            <FormGroup>
              <Input
                name="cardNumber"
                control={control}
                label="Frikortsnummer"
                placeholder="6-9 tecken"
              />
            </FormGroup>

            <Box mt={3} />
            <FormGroup>
              <DatePicker
                name="expirationDate"
                controller={control}
                label="Utgångsdatum"
              />
            </FormGroup>
          </div>
          <Box className={classes.buttonContainer}>
            <Button
              className={classes.button}
              disabled={isSubmitting}
              size="large"
              variant="contained"
              type="submit"
            >
              <Typography variant="h5">Spara</Typography>
            </Button>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button onClick={() => goBack()}>
              <Typography variant="h6" style={{ textTransform: "none" }}>
                Tillbaka
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const { caregiver, user } = state
  const { id: userId } = user

  return { caregiver, userId }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCaregiver: (userId) => dispatch(getCaregiver(userId)),
  getUserProfile: (userId) => dispatch(fetchUserProfile(userId)),
  showAlert: (data) => dispatch(displayAlert(data)),
  setChatStarted: (data) => dispatch(changeChatToStarted(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FrikortModal)
